.dealmed-number-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  min-height: 44px;
  max-height: 46px;
  border: 1px solid $grey-light-2;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  @media screen and (max-width: 767px) {
    padding: 0 4px;
    min-height: 32px;
  }
}

.dealmed-number-input__button {
  padding: 0;
  color: $grey-mid-2;
  background-color: transparent;
  border: none;
  & svg {
    @media screen and (max-width: 767px) {
      width: 10px;
      height: 10px;
    }
  }
  &:hover,
  &:focus {
    color: $red-dark;
  }
}

.dealmed-number-input__label {
  flex: 1;
  margin: 0;
  color: #384349;
}

.dealmed-number-input__input {
  padding: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  appearance: textfield;
  @media screen and (max-width: 767px) {
    font-size: 10px;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: textfield;
    margin: 0;
  }
}
