.dealmed-products-dialog__open-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-right: 0.25rem;
  padding: 0.5rem 1rem;
  min-width: 145px;
  color: $white;
  background-color: $red-dark;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: $grey-dark;
  }

  .dealmed-products-dialog__icon-wrapper {
    margin-right: 0.5rem;
  }
}

.dealmed-products-dialog {
  .dealmed-dialog__content {
    &[data-reach-dialog-content] {
      margin: 0;
      padding: 1rem;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }
}

.dealmed-products-dialog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.dealmed-products-dialog__title {
  margin: 0;
  color: $red-dark;
  font-size: 1.25rem;
  font-weight: 700;
}

.dealmed-products-dialog__icon-wrapper {
  display: flex;
}

.dealmed-products-dialog__logo-icon {
  width: 100px;
  height: 100px;
}

.dealmed-products-dialog__close-btn {
  background-color: transparent;
  border: none;

  &:hover,
  &:focus {
    color: $red-dark;
  }
}
