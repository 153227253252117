a,
button {
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out,
    border-color 150ms ease-in-out;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}
