@import '../../assets/CSS/global_variables';
////////////////////////////
///////PACKAGE STYLES///////
////////////////////////////
/* Position and sizing of burger button */

.bm-burger-button {
  ////This gets overriden if using our custom styles below
  ////This gets overriden if using our custom styles below
  width: 36px; ////This gets overriden if using our custom styles below
  height: 30px; ////This gets overriden if using our custom styles below
  // left: 36px;           ////This gets overriden if using our custom styles below
  // top: 36px;            ////This gets overriden if using our custom styles below
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */

.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */

.bm-menu {
  background: #373a47; ////This gets overriden with our custom styles
  margin-top: 87px;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
  fill: #373a47;
  fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  padding-top: 20px;
}

/* Individual item */

.bm-item {
  display: inline-block;
  outline: none !important;
}

/* Styling of overlay */

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
}

.bm-menu-wrap {
  top: 0;
  left: 0;
  background-color: white;
}

////////////////////////////
///////CUSTOM STYLES////////
////////////////////////////
.sidebar-logged-in {
  padding: 10px 10px 0px 10px;
  .mdi-settings {
    color: $color-primary;
  }
}

.sidebar-custom-burger-button {
  position: relative;
  width: 29px;
  height: 23px;
  left: 25px; // top: 25px;
}

.sidebar-custom-main-menu-item {
  color: $color-primary;
  font-size: 18px;
}

.sidebar-custom-bm-menu {
  background: white;
  width: 100%;
}

.main-menu-item-header-custom {
  color: $color-primary !important;
  background-color: white !important;
  padding: 0px 0px 0px 0px !important;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border-bottom: 1px solid $color-background-gray-dark;
}

.sidebar-icon-carousel-right-arrow {
  font-size: 25px;
  color: $color-primary;
  position: absolute;
  right: 4px;
  top: 34.5%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transition: all 0.3s;
}

.sidebar-nav-tabs-wrapper[aria-expanded='true'] .sidebar-icon-carousel-right-arrow {
  transform: rotate(90deg) translate(-75%, 25%);
  -webkit-transform: rotate(90deg) translate(-75%, 25%);
  -moz-transform: rotate(90deg) translate(-75%, 25%);
  -ms-transform: rotate(90deg) translate(-75%, 25%);
  -o-transform: rotate(90deg) translate(-75%, 25%);
}

.sidebar-subcategory-item {
  padding: 0px 0px 10px 20px;
  font-size: 16px;
  color: $color-primary;
}

.sidebar-x-scroll-none {
  overflow-x: hidden;
}

.sidebar-unbulleted-list {
  list-style-type: none;
}

.sidebar-login-text {
  position: relative;
  bottom: 12px;
  font-size: 18px;
}

.sidebar-account-text {
  position: relative;
  bottom: 10px;
}

.sidebar-text-gray {
  color: $color-text-gray-dark;
}
