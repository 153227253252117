@import './base/vars.scss';
@import './base/_base.scss';
@import './base/_mixins.scss';
@import './base/_media-mixin.scss';
@import './base/_ext.scss';
@import './base/_btn.scss';
@import './base/_typography.scss';
@import './base/_form.scss';
// @import "header";
// @import "footer";

//breadcrums

// *,
// *::before,
// *::after {
//   box-sizing: content-box;
// }

.bb {
  padding: 70px 16px;

  &__steps {
    display: flex;
    align-items: center;
    margin-bottom: 47px;

    a {
      font-size: 16px;
      //line-height: 50px;
      letter-spacing: -0.16px;
      text-decoration: none;
      margin-right: 5px;
    }

    &--noactive {
      color: $clr-grey;
      font-family: 'Campton Book', sans-serif;
    }

    &--active {
      color: $clr-blackActive;
      font-weight: 500;
    }
  }

  &__name {
    &-cc {
      font-size: 35px;
      font-weight: 500;
      //line-height: 20px;
      color: $clr-grey;
      padding-left: 5px;
    }
  }

  &__marginLeft {
    @include media('<=phone-m') {
      margin-left: 17px;
    }
  }
}

.fp {
  background-color: #f9eeeb;
  padding: 70px 16px 45px 16px;
  position: relative;

  @include media('>=tablet') {
  }

  &__top {
    @include d-flex-between;
    margin-bottom: 40px;
    flex-direction: column;
    @include media('>=tablet') {
      flex-direction: row;
      margin-bottom: 62px;
    }
  }

  &__title {
    margin-bottom: 25px;
    @include media('>=tablet') {
      margin-bottom: 0;
    }
  }

  &__filters {
    width: 100%;
    max-width: 100%;
    @include d-flex-between;

    @include media('<=phone-l') {
      flex-direction: column;
    }

    @include media('>=tablet') {
      max-width: 565px;
      flex-direction: row;
    }

    &-block {
      @include media('<=phone-l') {
        width: 100%;
        margin-bottom: 20px;
      }

      select {
        background: #f1f3f7;
        padding: 8px 19px 8px 12px;
        border: 0;
        font-size: 13px;
        color: #000;

        @include media('<=phone-l') {
          width: 100%;
          padding: 16px 19px;
        }
      }
    }
  }

  &__swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;

    &-wrapper {
      margin-bottom: 70px;
    }
  }

  &__slider {
    @include media('<=tablet') {
      position: relative;
    }
  }

  &__slide {
    &-texts {
      box-sizing: border-box;
      max-height: 136px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-box {
      max-width: 214px;
      background: $clr-white;
      padding: 26px 25px 25px 25px;
      position: relative;
      margin: 0 auto;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 7px;
        background: #b92a47;
        display: none;
      }

      &:hover {
        &::after {
          display: block;
        }
      }
    }

    &-link {
      text-decoration: none;
    }

    &-image {
      text-align: center;
    }

    &-manufacturer {
      font-family: 'Calibri', sans-serif;
      font-size: 12px;
      line-height: 38px;
      letter-spacing: -0.12px;
      color: #b92a47;
    }

    &-name {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.16px;
      font-weight: 400;
    }
  }

  &__navigation {
    &--prev {
      left: 9%;

      @include media('<=tablet') {
        left: -15px;
      }

      i {
        color: #b92a47;
        font-size: 23px;
      }

      &::after {
        display: none;
      }
    }

    &--next {
      right: 9%;
      @include media('<=tablet') {
        right: -15px;
      }

      i {
        color: #b92a47;
        font-size: 23px;
      }

      &::after {
        display: none;
      }
    }
  }

  &__pagination {
    text-align: center;
    margin-bottom: 30px;

    span {
      width: 10px;
      height: 10px;
      border: 2px solid rgba(185, 42, 71, 0.3);
      background: transparent;
      opacity: 100%;

      &.swiper-pagination-bullet-active {
        width: 14px;
        height: 14px;
        background: #b92a47;
        border: 0;
      }
    }
  }

  &__viewAll {
    text-align: center;

    &--btn {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: $clr-red;
    }
  }
}

.fs {
  margin-bottom: 80px;
  padding: 0 16px;

  @include media('>=tablet') {
    margin-bottom: 150px;
  }

  &__block {
    @include d-flex-between;
    margin-top: 180px;
    flex-wrap: wrap;

    @include media('>=phone-m') {
      margin-top: 285px;
    }

    @include media('>=tablet') {
      max-width: 100%;
      flex-wrap: nowrap;
    }
  }

  &__box {
    max-width: 100%;
    width: 100%;
    margin-bottom: 30%;
    &:nth-last-child(1) {
      margin-bottom: 10%;
    }

    @include media('>=phone-l') {
      max-width: 48%;
      margin-bottom: 30%;

      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }

    @include media('>=tablet') {
      max-width: 260px;
      margin-bottom: 0;
    }

    @include media('>=bs-desktop-m') {
      max-width: 336px;
    }

    &-top {
      background: #f1f3f7;
      margin-bottom: 37px;
      max-height: 230px;
      min-height: 230px;
      text-align: center;

      @include media('>=phone-l') {
        max-height: 192px;
        min-height: 192px;
      }

      img {
        margin-top: -190px;

        @include media('<=phone-l') {
          margin-top: -80px;
        }
      }

      &:hover {
        background: $clr-red;
      }
    }

    &-heading {
      font-size: 30px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 43.2px;
      letter-spacing: -0.3px;
    }

    &-links {
      display: flex;
      flex-direction: column;

      & > a {
        text-decoration: none;
        font-size: 20px;
        font-weight: 500;
        color: $clr-black;
        line-height: 40px;

        &:hover {
          color: $clr-red;
        }
      }
    }
  }
}

.fb {
  margin-bottom: 95px;

  &__desc {
    font-size: 20px;
    line-height: 32px;
    color: #000;
    margin-bottom: 30px;

    @include media('>=phone-l') {
      font-size: 25px;
      line-height: 40px;
    }
  }

  &__block {
    background: #f1f3f7;
    padding: 40px 16px;

    @include media('>=phone-l') {
      padding: 50px;
    }
  }

  &__slide {
    text-align: center;
    @include d-flex-center;

    &-box {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;

      @include media('>=480px') {
        width: 70%;
      }
    }
  }

  &__swiper {
    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__navigation {
    &--prev {
      left: 0;

      i {
        color: #b92a47;
        font-size: 23px;
      }

      &::after {
        display: none;
      }
    }

    &--next {
      right: 0;
      i {
        color: #b92a47;
        font-size: 23px;
      }
      &::after {
        display: none;
      }
    }
  }
}

.dg {
  display: flex;
  margin-bottom: 98px;
  flex-direction: column;
  position: relative;
  min-height: 425px;

  @include media('>=desktop') {
    flex-direction: row;
  }

  &__left {
    background: $clr-red;
    padding: 0 0 40px 0;
    display: flex;
    align-items: center;
    margin-right: 40px;
    width: 100%;
    margin-bottom: 40px;
    flex-direction: column;

    img {
      max-width: 100%;
      margin-bottom: 30px;

      @include media('>=tablet') {
        max-width: 425px;
        margin-bottom: 0;
        padding-left: 40px;
      }
    }

    @include media('>=tablet') {
      flex-direction: row;
      padding-bottom: 0;
    }

    @include media('>=desktop') {
      width: 68%;
      margin-bottom: 0;
    }

    @include media('>=desktop-m') {
      padding-left: 125px;
    }

    &-text {
      max-width: 524px;
      margin: 0 auto;

      h2 {
        color: $clr-white;
        text-align: center;
        line-height: 60px;
        font-size: 45px;
        margin-bottom: 10px;

        @include media('>=desktop-m') {
          line-height: 70px;
        }
      }
    }

    &-btn {
      text-align: center;
    }
  }

  &__right {
    width: 100%;
    height: auto;
    background: #b2343c;
    position: relative;

    &-text {
      max-width: 165px;
      padding: 60px;
      font-family: 'Campton', sans-serif;
      font-size: 37px;
      font-weight: 600;
      line-height: 52px;
      letter-spacing: -0.93px;
      color: #fff7f5;
      position: relative;

      @include media('>=desktop') {
        padding: 70px 0 0 60px;
      }
    }

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center;
      background-image: url('./img/dealmed-vacine-info.jpg');
      width: 100%;
      height: auto;
    }

    @include media('>=desktop') {
      width: 32%;
    }
  }
}

.rc {
  margin-bottom: 120px;
  padding: 0 16px;

  &-title {
    margin-bottom: 20px;
  }

  &-desc {
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.24px;
    color: $clr-blackActive;
    margin-bottom: 60px;
  }

  &-link {
    text-decoration: none;
    margin-bottom: 250px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    @include media('>=phone-l') {
      &:nth-last-child(-n + 2) {
        margin-bottom: 0;
      }
    }

    @include media('>=tablet') {
      margin-bottom: 0;
    }
  }

  &__block {
    @include d-flex-between;
    margin-top: 210px;
    flex-wrap: wrap;
    justify-content: center;
    @include media('>=phone-l') {
      justify-content: space-between;
    }

    @include media('>=tablet') {
      margin-top: 210px;
      max-width: 100%;
      flex-wrap: nowrap;
    }
  }

  &__box {
    max-width: 100%;
    width: 100%;
    background: $clr-lightGrey;
    position: relative;

    @include media('>=tablet') {
      max-width: 260px;
    }

    @include media('>=bs-desktop-m') {
      max-width: 336px;
    }

    &:hover {
      background: $clr-red;

      .rc__box-name {
        color: $clr-white;
      }

      .rc__box-browse {
        color: $clr-white;

        span {
          display: none;
        }
      }

      .rc__box-hoverline {
        display: block;
      }
    }

    &-image {
      margin-top: -190px;
    }

    &-texts {
      padding: 0 31px 55px 31px;
    }

    &-hoverline {
      position: absolute;
      display: none;
      left: 31px;
      right: 31px;
      bottom: 31px;
    }

    &-name {
      font-size: 40px;
      font-weight: 500;
      line-height: 50px;
      letter-spacing: -0.4px;
      max-width: 210px;
      color: $clr-blackActive;
      margin-bottom: 50px;
    }

    &-browse {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.65;
      text-transform: uppercase;
      color: $clr-blackActive;
    }
  }

  &.cfyi {
    padding: 70px 16px 70px 16px;
    margin-bottom: 0;

    @include media('>=tablet') {
      padding: 150px 16px 180px 16px;
    }
  }
}

.dr {
  margin-bottom: 80px;

  @include media('>=tablet') {
    margin-bottom: 130px;
  }

  &__block {
    background: $clr-red;
    padding: 30px 16px;

    @include media('>=tablet') {
      padding: 40px 75px;
    }
  }

  &__top {
    @include d-flex-between;
    margin-bottom: 20px;
    flex-direction: column;
    @include media('>=bs-desktop-m') {
      flex-direction: row;
    }
  }

  &__title {
    max-width: 750px;
    color: $clr-white;
    text-align: center;
    margin-bottom: 20px;
    @include media('>=bs-desktop-m') {
      text-align: left;
      margin-bottom: 0;
    }
  }

  &__btn {
    margin-bottom: 20px;
    @include media('>=bs-desktop-m') {
      margin-bottom: 0;
    }
  }

  &__desc {
    font-size: 25px;
    font-weight: 300;
    line-height: 35px;
    color: $clr-white;
    max-width: 1150px;
    text-align: center;
    @include media('>=bs-desktop-m') {
      text-align: left;
      font-size: 25px;
      line-height: 40px;
    }
  }
}

.tabs {
  position: relative;
  margin-bottom: 70px;

  @include media('>=tablet') {
    margin-bottom: 120px;
  }

  &__buttons {
    background: $clr-blackActive;
    padding: 15px 16px;
    margin-bottom: 70px;

    @include media('>=bs-desktop-m') {
      margin-bottom: 125px;
    }
  }

  &__holder {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__menu {
    position: relative;
    @include d-flex-between;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    @include media('>=540px') {
      justify-content: space-between;
    }

    @include media('>=bs-desktop-m') {
      flex-wrap: nowrap;
    }
  }

  &--btn {
    font-weight: 500;
    color: $clr-grey;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.28px;
    font-family: 'Campton', sans-serif;
    cursor: pointer;
    background: transparent;
    border: 0;

    padding: 20px 40px 20px 40px;
    @include media('>=bs-desktop-m') {
      padding: 20.1px 57px 17.1px 50px;
      font-size: 28px;
    }

    &.is-active {
      border-radius: 5px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #7d7f8a;
      color: $clr-white;

      & + .tabs__pane {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__contents {
    padding: 0 16px;
  }

  &__pane {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media('>=bs-desktop-m') {
      flex-direction: row;
    }

    &-titles {
      max-width: 100%;

      @include media('>=bs-desktop-m') {
        max-width: 710px;
      }
    }

    &-subtitle {
      font-size: 35px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: -0.88px;
      color: $clr-grey;
      margin-bottom: 25px;
    }

    &-desc {
      font-size: 24px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: -0.24px;
      color: $clr-blackActive;
    }

    &-lists {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      @include media('>=phone-l') {
        flex-direction: row;
        margin-top: 30px;
      }
    }

    &-list {
      padding-left: 20px;
      margin-right: 0;
      margin-bottom: 0;

      @include media('>=tablet') {
        margin-right: 140px;
      }

      &:nth-last-child(1) {
        margin-top: 0;
      }

      &-item {
        margin-bottom: 25px;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.18px;
        color: $clr-black;
        font-weight: 300;
        max-width: 100%;

        @include media('>=phone-l') {
          max-width: 320px;
        }

        span {
          font-weight: 500;
        }
      }
    }
  }
}

.tab-container .tab-contents:not(.active) {
  display: none;
}

.tab-container2 .tab-contents2:not(.active) {
  display: none;
}

/* responsive */
@media all and (max-width: 900px) {
  .tab-container .tab-item {
    height: auto;
  }

  .tab-container2 .tab-item2 {
    height: auto;
  }
}

.accordion {
  padding: 0 16px;
  margin-bottom: 120px;

  &__title {
    margin-bottom: 30px;
  }

  &__question {
    max-width: 90%;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.6px;
    font-weight: 400;
    color: $clr-black;

    @include media('>=tablet') {
      max-width: 1360px;
      font-size: 24px;
      line-height: 25px;
    }
  }

  &__answer {
    max-width: 90%;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.5px;
    font-weight: 400;
    color: $clr-black;
    padding-top: 20px;
    font-family: 'Campton Book', sans-serif;
    @include media('>=tablet') {
      max-width: 1360px;
      font-size: 20px;
      line-height: 25px;
    }
  }

  &__label {
    position: relative;
    padding: 10px 0;
    font-size: 30px;
    color: #dddee2;
    cursor: pointer;

    &::before {
      content: '+';
      color: #dddee2;
      position: absolute;
      top: 5px;
      right: -5px;
      font-size: 30px;
    }
  }

  &__content {
    position: relative;
    background: white;
    height: 0;
    font-size: 20px;
    text-align: justify;
    max-width: 1360px;
    overflow: hidden;
    transition: all 0.33s ease-in-out;
  }

  &__block {
    position: relative;
    padding-bottom: 20px;
    border-bottom: 2px solid #dddee2;
    margin-bottom: 20px;

    &:nth-last-child(1) {
      border-bottom: 0;
      margin-bottom: 0;
    }

    &.active {
      .accordion__content {
        height: auto;
      }

      .accordion__label {
        &::before {
          content: '-';
          font-size: 30px;
        }
      }
    }
  }
}

.tcp {
  margin-bottom: 95px;

  &__block {
    background: $clr-blackActive;
    padding: 15px 16px 30px 16px;
  }

  &__categories {
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: normal;
    line-height: 40px;
    letter-spacing: -0.15px;
    color: $clr-white;
    text-transform: uppercase;
  }

  &__links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include media('>=phone-l') {
      flex-wrap: wrap;
    }
  }

  &__link {
    font-size: 15px;
    font-weight: normal;
    line-height: 40px;
    letter-spacing: -0.15px;
    color: $clr-white;
    padding: 10px 20px;
    border: 1px solid $clr-white;
    text-decoration: none;
    border-radius: 5px;

    &-block {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}

.cf {
  padding: 0 16px;
  margin-bottom: 70px;

  @include media('>=tablet') {
    margin-bottom: 120px;
  }

  &__title {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.36px;
    color: $clr-blackActive;
    margin-bottom: 25px;
  }

  &__subtitle {
    font-size: 22px;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: -0.22px;
    color: $clr-grey;
    margin-bottom: 50px;

    @include media('>=desktop') {
      margin-bottom: 90px;
    }
  }

  &__left {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 50px;
    @include media('>=desktop') {
      max-width: 524px;
      margin-right: 50px;
      margin-bottom: 0;
    }
  }

  &__right {
    max-width: 700px;
    margin: 0 auto;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media('>=desktop') {
      flex-direction: row;
    }
  }

  &__contact {
    &-block {
      display: flex;
      //align-items: center;
      margin-bottom: 25px;

      &--icon {
        margin-right: 30px;

        i {
          opacity: 0.4;
          font-size: 30px;
          transform: rotate(260deg);
          color: $clr-grey;
        }
      }

      &--link {
        font-size: 30px;
        font-weight: 500;
        //line-height: 66px;
        letter-spacing: -0.3px;
        color: $clr-blackActive;
        text-decoration: none;

        &:hover {
          color: $clr-red;
          text-decoration: underline;
        }
      }
    }
  }

  &__form {
    &-block {
      margin-right: 30px;
      min-width: 320px;

      &:nth-child(2) {
        margin-right: 0;
      }
    }

    &-small {
      opacity: 0.7;
      font-family: 'Campton', sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      color: $clr-grey;
    }

    &-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 70px;
    }

    &-body {
      margin-bottom: 45px;
    }

    &-bottom {
      margin-bottom: 25px;
    }

    &-submit {
      font-family: 'Campton', sans-serif;
      background: $clr-red;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 19.2px;
      letter-spacing: 1.2px;
      color: $clr-white;
      border: 0;
      padding: 25px;
      width: 100%;
      cursor: pointer;

      i {
        color: $clr-white;
        font-size: 16px;
        margin-right: 10px;
      }
    }

    textarea {
      font-family: 'Campton', sans-serif;
      border: 0;
      border-bottom: 1px solid $clr-grey;
      font-size: 18px;
      color: $clr-grey;
      letter-spacing: -0.18px;
      width: 100%;

      &:focus-visible {
        border: 0;
        outline: 0;
        border-bottom: 2px solid $clr-red;
      }
    }

    input {
      font-family: 'Campton', sans-serif;
      border: 0;
      border-bottom: 1px solid $clr-grey;
      width: 100%;
      max-width: 320px;
      min-height: 30px;
      font-size: 18px;
      line-height: 100%;
      color: $clr-grey;
      letter-spacing: -0.18px;

      &:focus-visible {
        border: 0;
        outline: 0;
        border-bottom: 2px solid $clr-red;
      }
    }
  }
}

.afsm {
  padding: 32px 38px 38px 38px;
  position: relative;

  @media screen and (min-width: 768px) and (max-width: 1079px) {
    padding: 32px;
  }
  @media screen and (max-width: 767px) {
    padding: 12px;
  }

  &__image {
    // background-image: url('./img/dealmed-hero-bg.jpg');
    width: 100%;
    min-height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    z-index: -1;
    border-radius: 16px;

    @media screen and (min-width: 768px) and (max-width: 1079px) {
      border-radius: 12px;
    }
    @media screen and (max-width: 767px) {
      padding: 8px;
    }
  }
  &__block {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px;
    @media screen and (min-width: 768px) and (max-width: 1079px) {
      padding: 32px;
    }
    @media screen and (max-width: 767px) {
      padding: 16px;
    }
  }

  &__title {
    margin-bottom: 25px;
    font-size: 48px;
    line-height: 115%;

    @media screen and (min-width: 768px) and (max-width: 1079px) {
      font-size: 37px;
    }
    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }

  h2 {
    margin-bottom: 30px;

    &.white {
      color: $clr-white;
    }
  }

  &__desc {
    font-size: 20px;
    color: white;
    margin-bottom: 32px;
    font-weight: 400;
    line-height: 125%;

    @media screen and (min-width: 768px) and (max-width: 1079px) {
      font-size: 18px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &__btn-shopnow {
    background-color: white;
    padding: 20px 32px;
    color: #cb0845;
    font-weight: 600;
    border-radius: 4px;
    font-size: 16px;
    line-height: 115%;
    display: inline-block;

    @media screen and (min-width: 768px) and (max-width: 1079px) {
      padding: 16px 28px;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
      padding: 15px 20px;
    }
  }
  &__left {
    max-width: 100%;
    width: 100%;

    @include media('>=tablet') {
      max-width: 700px;
      width: 70%;
      margin-bottom: 0;
    }
  }

  &__right {
    width: 100%;
    padding-left: 0;

    @include media('>=tablet') {
      width: 50%;
      padding-left: 40px;
    }
  }
}

.clir {
  padding: 36px 16px;
  background: linear-gradient(to bottom, #b92a47, #821f33);
  margin-bottom: 40px;

  @include media('>=tablet') {
    margin-bottom: 50px;
  }

  &__block {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include media('>=tablet') {
      align-items: center;
      flex-direction: row;
    }
  }

  &__title {
    margin-bottom: 25px;
    font-size: 36px;
    @include media('>=tablet') {
      font-size: 44px;
    }
  }

  h2 {
    margin-bottom: 30px;
    margin-bottom: 25px;
    font-size: 36px;
    @include media('>=tablet') {
      font-size: 44px;
    }

    &.white {
      color: $clr-white;
    }
  }

  &__desc {
    font-size: 20px;
    color: $clr-white;
    line-height: 28px;
    letter-spacing: -0.3px;
    margin-bottom: 55px;
    font-family: 'Campton-light', sans-serif;

    @include media('>=tablet') {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__left {
    max-width: 100%;
    width: 100%;
    margin-bottom: 50px;

    @include media('>=tablet') {
      max-width: 710px;
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__right {
    width: 100%;
    padding-left: 0;

    img {
      max-width: 100%;

      @include media('>=tablet') {
        max-width: 90%;
      }
    }

    @include media('>=tablet') {
      width: 50%;
      margin-left: 20px;
      text-align: right;
    }
  }
}

.hwhy {
  background: $clr-blackActive;
  padding: 28px 16px;
  margin-bottom: 36px;

  &__title {
    text-align: center;
    color: $clr-white;
    margin-bottom: 30px;
    font-size: 36px;
    font-family: 'Campton Book';

    @include media('>=tablet') {
      font-size: 44px;
      flex-wrap: nowrap;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media('>=tablet') {
      flex-wrap: nowrap;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0 30px;
    width: 100%;
    margin-bottom: 20px;
    align-self: flex-start;

    @include media('>=tablet') {
      width: unset;
      margin-bottom: 0;
    }

    &:nth-last-child(1) {
      &::after {
        @include media('>=tablet') {
          content: none;
        }
      }
    }

    &::after {
      position: absolute;
      content: '';
      width: 4px;
      height: 100px;
      background: $clr-white;
      right: 0;
      align-self: flex-start;

      @include media('<=tablet') {
        content: none;
      }
    }

    &-title {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.75px;
      text-align: center;
      color: $clr-white;
      margin-bottom: 5px;
      background: $clr-red;
      display: inline;
      padding: 5px;
    }

    &-desc {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 1.6px;
      text-align: center;
      color: $clr-white;
      font-family: 'Campton-light', sans-serif;
    }
  }
}

.ats {
  display: none;
  @include media('>=tablet') {
    display: block;
  }

  &__title {
    text-align: center;
    margin-bottom: 24px;
    font-size: 36px;
    color: #384349;

    @include media('>=tablet') {
      font-size: 44px;
    }
  }

  &__block {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    @include media('>=tablet') {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 16px 8px;
    width: 100%;
    margin-bottom: 0;
    min-height: 188px;

    @include media('>=phone-l') {
      margin-bottom: 60px;
    }

    @include media('>=tablet') {
      margin-bottom: 0;
    }

    &:hover {
      .ats__box-desc {
        color: $clr-red;
      }
    }

    &:nth-child(3) {
      @include media('<=tablet') {
        &::after {
          content: none;
        }
      }
    }

    &:nth-last-child(1) {
      &::after {
        content: none;
      }
    }

    &::after {
      position: absolute;
      content: '';
      width: 4px;
      height: 100%;
      background: #f9eeeb;
      right: -7px;
      display: none;

      @include media('>=phone-l') {
        display: block;
      }

      @include media('>=bs-desktop-m') {
        // right: -46px;
      }
    }

    &-image {
      max-width: 100px;
      min-width: 100px;
      max-height: 100px;
      min-height: 100px;
      border-radius: 6px;
      padding: 6px;
      margin: 0 auto 25px auto;
      box-shadow: 2px 2px 8px #b8babf;

      display: flex;
      align-items: center;
      justify-content: center;

      img {
        min-width: 90%;
        min-height: 90%;
        object-fit: cover;
      }
    }

    &-desc {
      font-size: 20px;
      line-height: 22px;
      letter-spacing: normal;
      color: $clr-grey;
      max-width: 160px;
      margin: 0 auto;
      text-align: center;
      font-family: 'Campton Book', sans-serif;
      border: 0;
      background: 0;
      padding: 0;
    }

    &.active {
      button {
        color: $clr-red;
      }
    }
  }

  &__panes {
    background: $clr-red;
  }
}

.moe {
  background-image: linear-gradient(to bottom, #b92a47, #811f33);
  padding: 36px 16px;
  margin-bottom: 36px;
  position: relative;

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @include media('>=bs-desktop-m') {
      flex-direction: row;
    }
  }

  &__left {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 36px;

    @include media('>=bs-desktop-m') {
      max-width: 500px;
      min-width: 500px;
      width: 35%;
      margin-right: 5%;
      margin-bottom: 0;
    }
  }

  &__right {
    width: 100%;
    @include media('>=bs-desktop-m') {
      width: 60%;
    }
  }

  &__title {
    margin-bottom: 22px;
    color: $clr-white;
    font-size: 44px;
  }

  &__desc {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.3px;
    color: $clr-white;
    margin-bottom: 36px;
    font-family: 'Campton-light', sans-serif;

    @include media('>=tablet') {
      font-size: 24px;
    }
  }

  &__swiper {
    // display: flex;
    // flex-direction: row;
    padding: 0 30px;

    @include media('>=tablet') {
      max-width: 90%;
    }

    //@include media(">=bs-desktop-m") {
    //  padding: 0 30px;
    //}
  }

  &__slider {
    position: relative;
  }

  &__slide {
    @include media('>=tablet') {
      margin-right: 16px !important;
    }
    &-box {
      background: $clr-white;
      padding: 16px;
      width: 240px;
      margin: 0 auto;
      aspect-ratio: 1/1;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
    }

    &-link {
      text-decoration: none;
    }

    &-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 150px;
      margin: 0 auto;
      // width:fit-content;
      height: 100px;
      // margin: 0 0;
      overflow: hidden;
      border-radius: 6px;
      box-shadow: 2px 2px 8px #b8babf;
    }

    &-image {
      max-width: 150px;
      max-height: 100px;
      object-fit: cover;
    }

    &-texts {
      max-width: 100%;
      overflow: hidden;
    }

    &-manufacturer {
      font-family: 'Calibri', sans-serif;
      font-size: 13px;
      line-height: 28px;
      letter-spacing: -0.13px;
      color: $clr-red;
      // width:100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      // width: 148px;
      display: block;
      // overflow: hidden
    }

    &-name {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.18px;
      color: #384349;
      font-weight: 400;
      max-height: 89px;
      // width:100%;
    }
  }

  &__navigation {
    &--prev {
      left: -15px;

      @include media('<=bs-desktop-m') {
        left: -15px;
      }

      i {
        color: $clr-white;
        font-size: 23px;
      }

      &::after {
        display: none;
      }
    }

    &--next {
      right: -15px;
      @include media('<=bs-desktop-m') {
        right: -15px;
      }

      i {
        color: $clr-white;
        font-size: 23px;
      }

      &::after {
        display: none;
      }
    }
  }

  // &__slide-image{
  //   height:116px;
  //   // width: 100%;
  // }
}

.wws {
  margin-bottom: 50px;
  padding: 0 16px;

  @include media('>=tablet') {
    margin-bottom: 50px;
  }

  &__titles {
    text-align: center;
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 0;
    font-size: 36px;
    color: #384349;

    @include media('>=tablet') {
      margin-bottom: 20px;
      font-size: 44px;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: $clr-blackActive;
    font-family: 'Campton', sans-serif;

    @include media('>=tablet') {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.5px;
    }
  }

  &__boxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__box {
    padding: 0 10px;

    &-link {
      background-color: $clr-lightGrey;
      //min-width: 456px;
      //max-width: 456px;
      width: 100%;
      min-height: 120px;
      max-height: 120px;
      @include d-flex-center;
      margin-bottom: 30px;
      text-decoration: none;
      font-size: 24px;
      font-weight: normal;
      line-height: 32px;
      letter-spacing: -0.3px;
      text-align: center;
      color: $clr-blackActive;
      border-radius: 6px;

      @include media('>=phone-l') {
        width: 48%;
      }

      @include media('>=phone-l') {
        width: 32%;
      }

      @include media('<=phone-l') {
        min-height: 82px;
      }
      @include media('<tablet') {
        margin-bottom: 16px;
      }

      &:hover {
        background-color: $clr-red;
        color: $clr-white;
        background-color: #f9eeeb;
        color: $clr-red;
        text-decoration: underline !important;
        box-shadow: 2px 2px 8px #e6d4cf;
      }
    }
  }
}

.vas {
  margin-bottom: 40px;
  position: relative;
  padding: 0 16px;

  &__titles {
    color: #384349;
    margin-bottom: 30px;
  }

  &__title {
    font-size: 36px;
    color: #384349;
    margin-bottom: 16px;

    @include media('>=tablet') {
      font-size: 44px;
      margin-bottom: 24px;
    }
  }

  &__desc {
    font-family: 'Campton Book', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.75;
    // padding-left: 30px;
    @include media('>=tablet') {
      font-size: 28px;
      line-height: 56px;
    }
  }

  &__image {
    position: absolute;
    right: 190px;
    z-index: -1;
    top: 250px;
    width: 65%;
    display: none;
    @include media('>=bs-desktop-m') {
      display: block;
    }
  }

  &__boxes {
    background-color: $clr-lightGrey;
    padding: 24px;
    max-width: 100%;

    @include media('>=phone-l') {
      padding: 24px;
    }

    @include media('>=bs-desktop-m') {
      width: 50%;
      max-width: 850px;
    }
  }

  &__box {
    padding-bottom: 24px;
    margin-bottom: 20px;
    border-bottom: 2px solid $clr-grey;

    &:hover {
      border-bottom: 2px solid $clr-red;

      .vas__box-title {
        color: $clr-red;
      }
    }

    &-link {
      text-decoration: none;
      cursor: pointer;
    }

    &-title {
      font-size: 24px;
      font-weight: 500;
      font-family: 'Campton Book', sans-serif;
      line-height: 32px;
      letter-spacing: -0.75px;
      color: #384349;
      margin-bottom: 8px;

      @include media('>=tablet') {
        font-size: 30px;
        line-height: 50px;
      }
    }

    &-desc {
      font-family: 'Campton-light', sans-serif;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.26px;
      color: $clr-grey;
      padding-left: 10px;
    }
  }

  &__content {
    margin-top: 15px;
  }

  &__body {
    display: none;
    font-size: 18px;

    &.active {
      display: block;
    }
  }
}

.adwm {
  position: relative;
  padding: 36px 0 30px 0;

  @include media('>=phone-l') {
    padding: 36px 0 30px 0;
  }

  &__hero {
    background-image: url('./img/adwm-backgroundImage.jpg');
    //width: 100%;
    //height: 100%;
    //background-size: cover;
    //background-position: center;
    //background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @include media('<=phone-l') {
      background-repeat: no-repeat;
    }
  }

  &__title {
    margin-bottom: 30px;
    color: $clr-white;
    font-size: 36px;

    span {
      color: $clr-red;
    }

    @include media('>=tablet') {
      font-size: 44px;
    }
  }

  &__desc {
    font-family: 'Campton-light', sans-serif;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.3px;
    color: $clr-white;
    max-width: 540px;

    @include media('>=tablet') {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__block {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    flex-direction: column;
    padding: 0 16px;
    @include media('>=phone-l') {
      flex-direction: row;
      justify-content: space-around;
    }

    @include media('>=tablet') {
      margin-bottom: 50px;
    }

    @include media('>=desktop-m') {
      padding: 0;
    }
    @include media('<=phone-l') {
      margin-bottom: 270px;
    }
  }

  &__left {
    max-width: 100%;
    margin-bottom: 50px;
    @include media('>=phone-l') {
      max-width: 62%;
      margin-bottom: 0;
    }
  }

  &__right {
    width: 100%;
    text-align: center;
    @include media('>=phone-l') {
      width: 38%;
      text-align: right;
    }
  }

  &__boxes {
    display: flex;
    align-items: space-between;
    justify-content: space-between;
    padding: 0 50px;
    flex-wrap: wrap;
    @include media('>=tablet') {
      flex-wrap: nowrap;
    }
  }

  &__box {
    text-align: center;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    height: 140px;

    @include media('>=phone-l') {
      width: 50%;
      margin-bottom: 0;
    }

    @include media('>=tablet') {
      width: 25%;

      &:nth-last-child(1) {
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        width: 2px;
        height: 100%;
        background: $clr-white;
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: 0.58;
      }
    }

    &-icon {
      margin: 0 auto 15px auto;
      max-width: 85px;
    }

    &-text {
      font-size: 20px;
      // font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.3px;
      color: $clr-blackActive;
      padding: 0 10px;

      @include media('>=phone-l') {
        color: $clr-lightGrey;
      }

      @include media('>=tablet') {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

.eynioa {
  background-color: #f9eeeb;
  position: relative;
  padding: 24px 16px 24px 16px;
  overflow: hidden;

  @include media('>=tablet') {
    padding: 100px 16px 135px 16px;
  }

  &__titles {
    max-width: 100%;
    margin-bottom: 50px;

    @include media('>=tablet') {
      max-width: 610px;
      margin-bottom: 0;
    }
  }

  &__image {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: right;

    @include media('>=tablet') {
      position: absolute;
      bottom: -260px;
      right: 20px;
      width: 55%;
      text-align: right;
    }

    @include media('>=bs-desktop-m') {
      width: 70%;
      text-align: right;
      bottom: -350px;
    }
  }

  &__title {
    margin-bottom: 30px;
    color: #384349;
    font-size: 36px;

    @include media('>=tablet') {
      margin-bottom: 60px;
      font-size: 44px;
    }
  }

  &__desc {
    font-family: 'Campton-light', sans-serif;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.3px;
    margin-bottom: 40px;
    color: $clr-grey;

    @include media('>=tablet') {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    margin-bottom: 200px;
  }

  &__left {
    max-width: 62%;
  }

  &__right {
    width: 38%;
    text-align: right;
  }

  &__boxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
  }

  &__box {
    text-align: center;
    position: relative;
    width: 25%;

    &:nth-last-child(1) {
      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      width: 2px;
      height: 100%;
      background: $clr-white;
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0.58;
    }

    &-icon {
      margin: 0 auto 15px auto;
      max-width: 85px;
    }

    &-text {
      font-size: 30px;
      font-weight: 700;
      line-height: 40px;
      letter-spacing: -0.3px;
      color: $clr-lightGrey;
    }
  }
}

.ltcob {
  // margin-bottom: 70px;
  background-color: $clr-red;
  padding: 40px 16px 0 16px;
  @include media('>=tablet') {
    // margin-bottom: 132px;
    padding: 0;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include media('>=tablet') {
      flex-direction: row;
    }
  }

  &__title {
    color: $clr-white;
    margin-bottom: 40px;
    font-size: 36px;

    @include media('>=tablet') {
      font-size: 44px;
      margin-bottom: 60px;
    }
  }

  &__titles {
    max-width: 100%;

    @include media('>=desktop') {
      max-width: 436px;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
  }

  &__left {
    order: 2;
    @include media('>=tablet') {
      order: unset;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    order: 1;
    margin-bottom: 40px;
    @include media('>=tablet') {
      order: unset;
      margin-bottom: 0;
    }
  }
}

.atsm {
  padding: 0 16px;
  margin-bottom: 40px;
  display: block;

  @include media('>=tablet') {
    display: none;
  }

  &__title {
    text-align: center;
    margin-bottom: 48px;
    color: #384349;
    font-size: 30px;
    line-height: 34px;
  }

  &__block {
    border-bottom: 1px solid $clr-lightGrey;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  &__accordion {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::before {
      content: '+';
      color: $clr-blackActive;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -5px;
      font-size: 30px;
    }

    &-image {
      width: 80px;
      max-width: 80px;
      min-width: 80px;
      padding: 6px;
      margin-right: 25px;
      border-radius: 6px;
      background: $clr-lightGrey;
      box-shadow: 2px 2px 8px #b8babf;

      img {
        width: 100%;
      }
    }

    &-title {
      font-size: 20px;
      line-height: 27px;
      letter-spacing: normal;
      color: #384349 !important;
      font-family: 'Campton Book', sans-serif;
      margin-right: 20px;

      h4 {
        color: #384349 !important;
        font-size: 20px;
      }
    }

    &-desc {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: $clr-blackActive;
      margin-bottom: 12px;
      font-family: 'Campton-light', sans-serif;
      padding-top: 30px;
      color: #384349 !important;
    }

    &-content {
      display: none;
      overflow: hidden;
      transition: all 0.33s ease-in-out;
    }

    &.is-open {
      &::before {
        content: '-';
      }

      & + .atsm__accordion-content {
        display: block;
      }
    }
  }

  .moe__navigation--prev {
    left: 0;
    font-size: 23px;
    color: $clr-blackActive;
  }

  .moe__navigation--next {
    right: 0;
    font-size: 23px;
    color: $clr-blackActive;
  }

  .moe__slide-image-container {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 2px 2px 6px #b8babf;
  }

  .moe__slide-image {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  .moe__slide-manufacturer {
    font-family: 'Campton-light';
    padding: 8px 0;
  }

  .moe__slide-name {
    font-family: 'Campton-light';
    font-size: 20px;
    color: #384349 !important;
  }

  .moe__slide-box {
    padding: 12px;
  }
}

.po {
  position: relative;
  min-height: 600px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  @include media('>=phone-l') {
    min-height: 835px;
  }

  &__image {
    background-image: url('./img/dealmed-newbg.jpg');
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  &__block {
    position: relative;
    width: 100%;

    @include media('>=phone-l') {
      width: 50%;
    }
  }

  &__title {
    font-size: 56px;
    font-weight: 500;
    line-height: 70px;
    letter-spacing: -2px;
    text-align: left;
    color: $clr-white;
    margin-bottom: 50px;

    @include media('>=phone-l') {
      font-size: 80px;
      line-height: 90px;
    }
  }

  &__desc {
    font-family: 'Campton', sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.22px;
    color: $clr-white;
  }
}

.tbp {
  padding: 70px 16px 50px 16px;
  @include media('>=tablet') {
    padding: 90px 16px;
  }

  &__text {
    margin-bottom: 50px;
  }

  &__miniTitle {
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.55px;
    color: $clr-red;
    margin-bottom: 30px;
  }

  &__title {
    max-width: 1220px;
    margin-bottom: 40px;
  }

  &__desc {
    font-family: 'Campton', sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.22px;
    color: $clr-grey;

    span {
      font-weight: 500;
    }
  }

  &__boxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include media('>=tablet') {
      flex-wrap: nowrap;
    }
  }

  &__box {
    max-width: 100%;
    margin-bottom: 60px;

    @include media('>=phone-l') {
      max-width: 295px;
    }

    @include media('>=tablet') {
      margin-bottom: 0;
    }

    &-image {
      margin-bottom: 20px;

      @include media('>=phone-l') {
        margin-bottom: 70px;
      }
    }

    &-desc {
      font-family: 'Campton', sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 40px;
      letter-spacing: -0.24px;
      color: $clr-blackActive;
    }
  }
}

.aaws {
  background-color: $clr-lightGrey;
  padding: 70px 16px;
  position: relative;

  @include media('>=tablet') {
    padding: 130px 16px;
  }

  &__image {
    position: relative;
    width: 100%;
    right: 0;
    top: 0;
    transform: none;

    @include media('>=tablet') {
      position: absolute;
      width: 60%;
      right: -100px;
      top: 54%;
      transform: translateY(-50%);
    }
  }

  &__text {
    margin-bottom: 50px;
  }

  &__miniTitle {
    font-size: 22px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.55px;
    color: $clr-red;
    margin-bottom: 50px;
  }

  &__title {
    margin-bottom: 50px;
    color: $clr-blackActive;
  }

  &__desc {
    font-family: 'Campton', sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: -0.22px;
    color: $clr-blackActive;
    margin-bottom: 50px;
    max-width: 480px;

    span {
      font-weight: 500;
    }
  }

  &__list {
    padding-left: 20px;
    margin-bottom: 50px;
    @include media('>=tablet') {
      margin-bottom: 0;
    }

    &-item {
      font-size: 20px;
      font-weight: 300;
      line-height: 40px;
      letter-spacing: -0.2px;
      color: $clr-blackActive;
      margin-bottom: 6px;

      span {
        font-weight: 500;
      }
    }
  }
}

.sfyi {
  padding: 0 16px;
  margin-bottom: 70px;

  @include media('>=tablet') {
    margin-bottom: 200px;
  }

  &__title {
    margin-bottom: 25px;
  }

  &__desc {
    font-family: 'Campton', sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.24px;
    text-align: left;
    color: $clr-blackActive;
    margin-bottom: 90px;
  }

  &__boxes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include media('>=tablet') {
      flex-direction: row;
    }
  }

  &__box {
    width: 100%;
    margin-right: 0;
    max-width: 100%;
    height: 100%;
    background: #f9eeeb;
    cursor: pointer;
    padding-bottom: 60px;
    margin-bottom: 40px;

    @include media('>=tablet') {
      width: 48%;
      margin-right: 2%;
      max-width: 702px;
      height: 100%;
      margin-bottom: 0;
    }

    @include media('>=1400px') {
      min-height: 550px;
      padding: 0;
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }

    &-block {
      padding: 40px 25px;

      @include media('>=phone-l') {
        padding: 70px 80px;
      }
    }

    &-top {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      flex-direction: column;
      @include media('>=1400px') {
        flex-direction: row;
      }
    }

    &-image {
      margin-right: 0;
      margin-bottom: 30px;

      &-noHover {
        display: block;
      }
      &-hover {
        display: none;
      }

      @include media('>=1400px') {
        margin-right: 40px;
        margin-bottom: 0;
      }
    }

    &-title {
      font-size: 36px;
      line-height: 44px;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: $clr-red;

      @include media('>=tablet') {
        font-size: 50px;
        line-height: 62px;
      }
    }

    &-desc {
      font-family: 'Campton', sans-serif;
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: -0.24px;
      color: $clr-black;
      margin-bottom: 60px;

      @include media('>=tablet') {
        font-size: 24px;
        line-height: 34px;
      }

      span {
        font-weight: 500;
      }
    }

    &-btn {
      display: none;
      background: $clr-white;
      position: absolute;
      @include media('>=1400px') {
        position: relative;
      }
    }

    &:hover {
      background: $clr-red;

      .sfyi__box-title {
        color: $clr-white;
      }

      .sfyi__box-desc {
        color: $clr-white;
      }

      .sfyi__box-btn {
        display: inline-flex;
        text-decoration: none;
        padding: 17px 42px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 1.2px;
        color: $clr-red;
        text-transform: uppercase;
      }

      .sfyi__box-image {
        &-noHover {
          display: none;
        }
        &-hover {
          display: block;
        }
      }
    }
  }
}

.rsc {
  margin-bottom: 70px;
  padding: 0 16px;
  background-color: $clr-lightGrey;

  @include media('>=tablet') {
    padding: 150px 0;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    @include media('>=phone-l') {
      justify-content: space-between;
      flex-direction: row;
    }

    @include media('>=tablet') {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }

  &__box {
    text-align: center;
    margin-bottom: 40px;
    background-color: $clr-white;
    margin-right: 100px;
    max-width: 266px;
    padding: 33px 16px 0;
    @include media('>=tablet') {
      margin-bottom: 0;
    }

    &-title {
      font-size: 22px;
      font-weight: 500;
      // line-height: 66px;
      letter-spacing: -0.4px;
      color: $clr-blackActive;
    }

    &-image {
      margin: 33px 0;
    }
  }

  &__pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 45px;
    font-family: 'Campton', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: -0.2px;
    color: $clr-red;
    text-decoration: none;

    @include media('>=phone-l') {
      font-size: 20px;
      line-height: 68px;
    }

    &-icon {
      max-width: 23px;
      margin-right: 30px;
    }
  }
}

.coon {
  margin-bottom: 100px;
  padding: 0 16px;

  &__title {
    margin-bottom: 25px;
  }

  &__desc {
    font-family: 'Campton', sans-serif;
    font-size: 24px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: -0.24px;
    text-align: left;
    color: $clr-blackActive;
    margin-bottom: 50px;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include media('>=phone-l') {
      flex-direction: row;
    }
  }

  &__left,
  &__right {
    width: 100%;

    @include media('>=phone-l') {
      width: 50%;
    }
  }

  &__left {
    max-width: 100%;
    margin-bottom: 60px;

    @include media('>=phone-l') {
      max-width: 582px;
      margin-bottom: 0;
    }
  }

  &__btn {
    background: $clr-red;
    text-transform: uppercase;
    font-family: 'Campton', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 1.2px;
    color: $clr-lightGrey;
    padding: 17px 48px;
    text-decoration: none;
  }
}

.custom-slick-prev,
.custom-slick-next {
  position: absolute;
  top: 50%;
  z-index: 100;
  cursor: pointer;
  /* Adjust these values as needed */
}

.custom-slick-prev {
  left: -25px;
}

.custom-slick-next {
  right: -25px;
}

.slick-list {
  padding-top: 10px !important;
}
