//////////////  GLOBAL COLORS  //////////////

// NEW NEW DESIGN COLORS - HIGH CONTRAST
//DarkBlue

$DarkBlue-900: #2b3654;
$DarkBlue-800: #404a65;
$DarkBlue-700: #555e76;
$DarkBlue-600: #6b7287;
$DarkBlue-500: #808698;
$DarkBlue-400: #959ba9;
$DarkBlue-300: #aaafbb;
$DarkBlue-200: #bfc3cc;
$DarkBlue-100: #d5d7dd;
$DarkBlue-50: #eaebee;
$DarkBlue-05: #f4f5f7;

//MetalGrey

$MetalGrey-900: #63769c;
$MetalGrey-800: #7384a6;
$MetalGrey-700: #8291b0;
$MetalGrey-600: #929fba;
$MetalGrey-500: #a1adc3;
$MetalGrey-400: #b1bbcd;
$MetalGrey-300: #c1c8d7;
$MetalGrey-200: #d0d6e1;
$MetalGrey-100: #e0e4eb;
$MetalGrey-50: #eff1f5;
$MetalGrey-05: #f7f8fa;

//DarkRed

$DarkRed-900: #a32e49;
$DarkRed-800: #ac435b;
$DarkRed-700: #b6586e;
$DarkRed-600: #bf6d80;
$DarkRed-500: #c88292;
$DarkRed-400: #d197a4;
$DarkRed-300: #daabb6;
$DarkRed-200: #e3c0c9;
$DarkRed-100: #edd5db;
$DarkRed-50: #f6eaed;
$DarkRed-05: #fbf5f6;

//Scarlet

$Scarlet-900: #c72344;
$Scarlet-800: #cd3957;
$Scarlet-700: #d24f69;
$Scarlet-600: #d8657c;
$Scarlet-500: #dd7b8f;
$Scarlet-400: #e391a1;
$Scarlet-300: #e9a7b4;
$Scarlet-200: #eebdc7;
$Scarlet-100: #f4d3da;
$Scarlet-50: #f9e9ec;
$Scarlet-05: #fcf4f6;

// NEW DESIGNS
$almost-black: #2e3547;
$dark-grey: #2b3654;
$medium-grey: #8f9cbc;
$almost-white: #e9eefb;
$border-gray: #ecf1f9;
$grey: #d4d4d6;
$almost-grey: #f8f9fd;
$red-background-one: #a42e49;
$red-two: #ca2344;
$color-in-stock: #42d78c;
$white: #fff;
$online: #4cda85;
$card-grey-bg: #f7f8fc;
$main-background: #fbfcfe;
$red-button-linear-red-bckgrnd: linear-gradient(45deg, $red-background-one -15%, $red-two 118.75%);
$red-button-linear-red-bckgrnd-hover: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.16),
    rgba(0, 0, 0, 0.16)
  ),
  linear-gradient(45deg, $red-background-one -15%, $red-two 118.75%);

/////////////////////////
// Primary Color Variants
/////////////////////////
//$color-primary:#A22E40;
$color-primary: #7a142c;
$color-primary-darker: #5f0000;
$color-primary-bright: #db4953;
$color-primary-brighter: #df5d66;
$color-primary-danger: #b92a47;
$color-primary-danger-light: #f9eeeb;
$color-dealmed-grey: rgb(92, 105, 105);
$color-price: #b12704;

$color-secondary: #7a142c;
$color-secondary-bright: #a8434c;
$color-secondary-brighter: #af5658;

//hover styles:
$color-primary-hover: #5f0000;
$color-primary-hover-bright: #b40000;
$pretty--color-primary: #730000;

/////////////////////
// Background Colors
/////////////////////
$color-background-gray: #ededed;
$color-background-gray-light: #f5f5f5;
$color-background-gray-dark: #dcdcdc;
$color-background-blue-gray: #f1f4f9;

/////////////////
// Button Colors
/////////////////
$color-save-green: #239e3e;
$color-save-green-hover: #078322;
$color-delete: #a83535;
$color-delete-hover: #9e1111;

///////////////
// Text Colors
///////////////
$color-text-gray: #808080;
$color-text-gray-dark: #5a5a5a;
$color-text-black: #1f2223;
$color-text-hero-secondary: #333333;

//////////////////  GLOBAL FONTS ///////////////
$font-archivo: 'Archivo', sans-serif;
$font-calibri: 'Calibri', sans-serif;
$font-campton: 'Campton', sans-serif;
:export {
  colorPrimary: $color-primary;
  colorPrimaryDarker: $color-primary-darker;
  colorPrimaryBright: $color-primary-bright;
  colorPrice: $color-price;
  colorPrimaryHover: $color-primary-hover;
  colorPrimaryHoverBright: $color-primary-hover-bright;
}
$font-manrope: 'Manrope', sans-serif;

// NEW REDESIGN //
$button-bg-grey: #eaebee;
$paid-color: #4a3ac5;
$paid-background: #edebf9;

$font-family-primary: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-secondary: 'Archivo', sans-serif;
$font-archivo: 'Archivo', sans-serif;
$font-calibri: 'Calibri', sans-serif;
$font-campton: 'Campton', sans-serif;
$font-campton-light: 'Campton-light', sans-serif;
$font-campton-bold: 'Campton-bold', sans-serif;
$font-campton-book: 'Campton Book', sans-serif;

///////////////
// V2 Design
///////////////
$text-neutral-0: black;
$text-neutral-90: #f4f3f1;
$text-neutral-80: #b3afa8;
$text-neutral-50: #7e7c78;
$text-neutral-30: #464041;
$color-point-red: #ed2525;
$color-point-red-60: #990000;
$color-point-red-80: #df919a;
$color-point-red-90: #ffe3e3;

$font-family-new: 'IBM Plex Sans';
