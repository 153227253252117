.dealmed-content-item {
  display: flex;
}

.dealmed-content-item__asset {
  margin-right: 1.25rem;
}

.dealmed-content-item__icon-wrapper {
  display: flex;
}

.dealmed-content-item__icon-wrapper > * {
  width: 4.375rem;
  height: 4.375rem;
  fill: $red-dark;
}

// .dealmed-content-item__icon {
// 	width: 4.375rem;
// 	height: 4.375rem;
// 	fill: $red-dark;
// }

.dealmed-content-item__content {
  color: $grey-dark;
  font-size: 1rem;

  p {
    margin: 0;
  }
}

@media only screen and (min-width: 62em) {
  .dealmed-content-item__asset {
    margin-right: 2.5rem;
  }

  .dealmed-content-item__content {
    line-height: 1.75rem;
    font-size: 1.125rem;
  }
}

@media only screen and (min-width: 75em) {
  .dealmed-content-item__asset {
    margin-right: 4rem;
  }

  .dealmed-content-item__content {
    font-size: 1.25rem;
  }
}
