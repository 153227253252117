@import '../../assets/CSS/globals';

.dealmed-content-heading {
  margin-bottom: 1.25rem;
}

.dealmed-content-heading__top {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.dealmed-content-heading__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  padding: 0.5rem;
  border: 2px solid $red-dark;
  border-radius: 50%;
}

.dealmed-content-heading__icon-wrapper > * {
  width: 2rem;
  height: 2rem;
  fill: $red-dark;
}

// .dealmed-content-heading__icon {
// 	width: 2rem;
// 	height: 2rem;
// 	fill: $red-dark;
// }

.dealmed-content-heading__subtitle {
  font-family: 'IBM Plex Sans';
  position: relative;
  margin: 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 24px;
  background-color: #c1001d;
  border-radius: 200px;
}

.dealmed-content-heading__middle {
  margin-bottom: 2.5rem;
}

.dealmed-content-heading__title {
  font-family: 'IBM Plex Sans';
  color: $text-neutral-0;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 125%;
}

.dealmed-content-heading__text {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  font-size: 24px;

  @media (max-width: $screen-xs-max) {
    //mobile
    font-size: 18px;
  }
}

.dealmed-content-heading__bottom {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-image: linear-gradient(0deg, $red-dark 0%, hsla(348, 63%, 45%, 0) 65%);
  }
}

.dealmed-content-heading__image {
  display: block;
  width: 100%;
  height: 300px;
  max-width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

@media only screen and (min-width: 48em) {
  .dealmed-content-heading {
    margin-bottom: 0;
  }

  .dealmed-content-heading__image {
    height: 400px;
  }
}

@media only screen and (min-width: 62em) {
  .dealmed-content-heading__subtitle {
  }

  .dealmed-content-heading__image {
    height: 560px;
  }
}

@media only screen and (min-width: 75em) {
  .dealmed-content-heading__subtitle {
  }
}
