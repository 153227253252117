.site-header {
  position: sticky;
  top: 0;
  background-color: $white;
  // box-shadow: 0 11px 9px -5px hsla(0, 0%, 64%, 0.23);
  z-index: 6000;
  border-bottom: 1px solid #e4e7e8;
}

.dealmed-header {
  &__main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 16px 20px;
    gap: 40px;
    border-bottom: 1px solid #e4e7e8;
  }
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #faf0f4;
    padding: 4px 20px;
    @media (min-width: 1024px) and (max-width: 1439px) {
      padding: 4px 20px;
    }
    &-item {
      border-right: 1px solid #0c0d0e30;
      padding-right: 12px;
      &:last-child {
        border-right: none;
      }
    }
    &-list {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
      margin: 0;
    }

    &-alertbanner {
      display: flex;
      gap: 10px;
      & span {
        color: #0c0d0e;
        font-family: 'IBM Plex Sans';
        font-size: 12px;
        font-weight: 600;
        @media (min-width: 1024px) and (max-width: 1439px) {
          font-size: 11px;
        }
      }
      &-link {
        color: #a42e49 !important;
        text-decoration: underline;
      }
    }
  }
  &__resources {
    &-link {
      color: #0c0d0e;
      font-family: 'IBM Plex Sans';
      font-size: 12px;

      @media (min-width: 1024px) and (max-width: 1439px) {
        font-size: 11px;
      }
      &:hover,
      &:focus {
        color: $red-darker;
        color: $grey-dark;
      }
    }
  }

  &__support {
    &-toggle {
      &-btn {
        display: flex;
        align-items: center;
        padding: 0;
        background-color: transparent;
        border: none;
        border-radius: 0;
        color: #0c0d0e;
        font-family: 'IBM Plex Sans';
        font-size: 12px;

        @media (min-width: 1024px) and (max-width: 1439px) {
          font-size: 11px;
        }

        .dealmed-header__icon-wrapper {
          margin-left: 6px;
          @media (min-width: 1024px) and (max-width: 1439px) {
            margin-left: 4px;
          }
          & svg {
            width: 12px;
            height: 12px;
            fill: #2f3537;
          }
        }

        &:hover,
        &:focus {
          color: $red-darker;
          color: $grey-dark;
        }
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 32px;
    &-menus {
      display: flex;
      align-items: center;
      gap: 32px;
      @media (max-width: 1280px) {
        gap: 8px;
      }
    }
    &-icons {
      display: flex;
      align-items: center;
    }
  }

  &__account {
    &-login {
      color: #a42e49;
      font-family: 'IBM Plex Sans';
      font-size: 14px;
      font-weight: 500;

      &:hover,
      &:focus {
        color: black;
      }
    }
  }

  &__register {
    &-link {
      color: white;
      font-size: 14px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 12px;
      border-radius: 6px;
      background-color: #a42e49;
      &:hover,
      &:focus {
      }
      & > svg {
        width: 16px;
        height: 16px;
        fill: #a42e49;
      }
    }
  }
}

.dealmed-header__skip-link {
  display: block;
  position: absolute;
  top: 20px;
  padding: 0.75em 1.25em;
  color: $white;
  background-color: $red-dark;
  border-radius: 5px;
  transform: translateX(-115%);
  transition: transform 150ms ease-in-out, color 150ms ease-in-out,
    background-color 150ms ease-in-out;
  z-index: 999999;
  &:hover,
  &:focus {
    color: $white;
    background-color: $grey-dark;
    transform: translateX(10%);
  }
}

.dealmed-header__top {
  .dealmed-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 24px;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-direction: row;
    }
  }
}

.dealmed-header__navigation {
  margin-right: 0;
}

.dealmed-header__logo-link {
  display: flex;
}

.dealmed-header__logo-icon {
  width: 8rem;
  height: 2rem;
}

.dealmed-header__icon-wrapper {
  display: flex;
  flex-shrink: 0;
}

.dealmed-header__row-group {
  display: flex;
  align-items: center;
  gap: 24px;
}

.dealmed-header__cart-empty {
  margin: 1rem 0;
  text-align: center;
}

.dealmed-header__cart-link {
  position: relative;
  color: $grey-dark;

  &:hover,
  &:focus {
    .dealmed-header__cart-icon {
      fill: $red-dark;
    }
  }
}

.dealmed-header__cart-icon {
  width: 2rem;
  height: 2rem;
  fill: $grey-dark;
}

.dealmed-popover__body {
  &::before,
  &::after {
    content: none;
  }
}

@media (max-width: 991px) {
  .dealmed-header__cart-items {
    top: -7px;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 24px;
    height: 24px;
    color: white;
    background-color: $red-dark;
    font-size: 12px;
    font-weight: 600;
    border-radius: 100px;
    font-family: 'IBM Plex Sans';
  }
}

.dealmed-header__reorder-icon {
  width: 2rem;
  height: 2rem;
}

.dealmed-header__bottom {
  .dealmed-container {
    padding-bottom: 16px;
    max-width: 90rem;
  }
}

.dealmed-header__search {
  position: relative;
  width: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border: 1px solid #ffe3e3;
    border-radius: 12px;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &:hover,
  &:focus-within {
    &::before {
      opacity: 1;
    }
  }
}

@media only screen and (min-width: 62em) {
  .dealmed-header__phone-link {
    display: flex;
    align-items: center;
    padding: 0.6rem 0;
    font-weight: 700;
    color: $grey-dark;
    font-family: $font-campton;
    font-size: 16px;
    text-align: center;

    &:hover,
    &:focus {
      color: $red-dark;
    }
  }

  .dealmed-header__phone-icon {
    fill: $red-dark;
    margin: 0 8px 0 0;
    transform: scale(-1, 1);
    height: 16px;
  }
  .dealmed-header__logo {
    // margin-right: 1rem;
  }

  .dealmed-header__logo-icon {
    width: 171px;
    height: unset;
    @media (min-width: 1024px) and (max-width: 1439px) {
      width: 152px;
    }
  }

  // .dealmed-header__search,

  .dealmed-header__support-popover {
    .dealmed-popover__body {
      font-family: 'IBM Plex Sans';
    }

    .dealmed-header__icon-wrapper {
      margin-right: 1rem;
    }

    .dealmed-phone-icon {
      width: 16px;
      height: 16px;
      fill: black;
    }
    .dealmed-speech-icon {
      width: 24px;
      height: 24px;
      fill: black;
      margin: 0 -4px;
    }
    .dealmed-envelope-icon {
      width: 28px;
      height: 28px;
      fill: black;
      margin: 0 -6px;
    }
    .dealmed-clock-icon {
      width: 18px;
      height: 18px;
      fill: black;
      margin: 0 -1px;
    }
  }

  .dealmed-header__support-list {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 0;
  }
  .dealmed-header__support-item {
    padding: 12px 12px;
  }

  .dealmed-header__support-item,
  .dealmed-header__support-phone,
  .dealmed-header__support-contact,
  .dealmed-header__support-email {
    display: flex;
    align-items: center;
    color: black;
    font-family: 'IBM Plex Sans';
    white-space: nowrap;
    border-radius: 8px;
    justify-content: flex-start;
    font-size: 14px;

    &:hover {
      color: $red-darker;
      background-color: #f4f3f1;
    }
  }

  .dealmed-header__support-phone,
  .dealmed-header__support-contact,
  .dealmed-header__support-email {
    &:hover,
    &:focus {
      color: $red-darker;

      .dealmed-icon {
        fill: $black;
      }
    }
  }

  .dealmed-header__account {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .dealmed-header__account-name {
    position: absolute;
    top: -16px;
    left: 12px;
    // min-width: 140px;
    // color: $grey-mid-3;
    font-size: 12px;
    color: black;
  }

  .dealmed-header__account-toggle-btn {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    line-height: normal;
    padding: 6px 12px;
    color: #a42e49;
    gap: 4px;

    & > svg {
      width: 16px;
      height: 16px;
      fill: #a42e49;
    }
    .dealmed-header__icon-wrapper > svg {
      width: 8px;
      height: 8px;
    }

    &:hover,
    &:focus {
      color: $red-darker;
      background-color: #f4f3f1;
    }
  }

  .dealmed-header__account-popover {
    .dealmed-popover__body {
      padding: 1rem;
      width: 315px;
    }
  }
  .dealmed-notification-popover {
    .dealmed-popover__body {
      width: 355px;
    }
  }
  .dealmed-header__account-header {
    margin-bottom: 1.5rem;
  }

  .dealmed-header__account-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .dealmed-header__account-link {
    color: $red-darker;
    font-weight: 700;

    &:hover,
    &:focus {
      color: $grey-dark;
    }
  }

  .dealmed-header__account-logout {
    padding: 0;
    color: $grey-mid-3;
    background-color: transparent;
    border: none;

    &:hover,
    &:focus {
      color: $red-darker;
    }
  }

  .dealmed-header__acccount-section {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .dealmed-header__account-section-title {
    font-size: 12px;
    color: #9b9191;
    font-family: 'IBM Plex Sans';
    letter-spacing: normal;
    margin-bottom: 4px;
  }
  .dealmed-header__account-section-item {
    border-radius: 8px;
    padding: 8px 12px;
    &:hover,
    &:focus {
      background-color: #f4f3f1;
      & .dealmed-header__account-section-list {
        color: $red-darker;
      }
    }
  }
  .dealmed-header__account-section-list {
    display: flex;
    flex-direction: column;
  }

  .dealmed-header__account-section-link {
    color: black;
    font-size: 16px;

    &:hover,
    &:focus {
      color: $red-darker;
    }
  }

  .dealmed-header__cart {
    margin-right: 0;
  }

  .dealmed-header__cart-toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    width: 48px;
    height: 48px;

    &:hover,
    &:focus {
      .dealmed-header__cart-icon {
        fill: $red-dark;
      }
    }
  }

  .dealmed-header__cart-items {
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 24px;
    height: 24px;
    color: white;
    background-color: $red-dark;
    font-size: 12px;
    font-weight: 600;
    border-radius: 100px;
    font-family: 'IBM Plex Sans';
  }

  .dealmed-header__cart-popover {
    .dealmed-popover__body {
      padding: 0;
      width: 324px;

      &::before,
      &::after {
        left: 325px;
      }
    }

    .dealmed-popover__content {
      position: relative;
      min-height: 45px;
    }
  }

  .dealmed-header__cart-list {
    margin-bottom: 0;
    padding: 1rem;
    min-height: 3rem;
    height: 2160px;
    max-height: calc(100vh - 155px);
    overflow-y: scroll;
  }

  .dealmed-header__cart-item {
    &:not(:last-child) {
      padding: 12px 0;
      border-bottom: 1px solid #f4f3f1;
    }
  }

  .dealmed-header__cart-product {
    display: flex;
    gap: 12px;
  }

  .dealmed-header__cart-details {
    flex: 1;
  }

  .dealmed-header__cart-image {
    display: block;
    margin: 0;
    width: 40px;
    height: 40px;
    object-fit: contain;
  }

  .dealmed-header__cart-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 2px;
  }

  .dealmed-header__cart-name {
    color: black;
    line-height: 120%;
    font-size: 14px;
    font-weight: 600;
  }
  .dealmed-header__cart-price-subtitle {
    display: flex;
    gap: 0 4px;
    align-items: flex-start;
    margin-top: 6px;
  }
  .dealmed-header__cart-quantity {
    font-size: 12px;
    font-size: 400;
    color: #3b4245;
  }
  .dealmed-header__cart-price-container {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-top: 8px;
  }

  .dealmed-header__cart-price {
    color: black;
    font-size: 14px;
    font-weight: 600;
  }

  .dealmed-header__cart-remove {
    color: $red-dark;
    background-color: transparent;
    border-radius: 8px;
    border: none;
    text-decoration: underline;
    font-size: 10px;
    color: #7e7c78;
    padding: 6px 8px;
    margin-top: 4px;
    transition: all 0.2s ease-in;

    &:hover,
    &:focus {
      color: $white;
      background-color: $red-dark;
      padding: 6px 8px;
    }
  }

  .dealmed-header__cart-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid #f4f3f1;

    &.p {
      font-weight: 600;
      font-size: 20px;
    }
    &-btn-close {
      background-color: transparent;
      border: none;
    }
  }
  .dealmed-header__cart-container {
    padding: 12px 16px;
    border-top: 1px solid #f4f3f1;
  }
  .dealmed-header__cart-totals,
  .dealmed-header__cart-links {
    display: flex;
    gap: 4px;
  }

  .dealmed-header__cart-totals {
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }

  .dealmed-header__cart-product-count,
  .dealmed-header__cart-subtotal {
    font-size: 14px;
  }

  .dealmed-header__cart-subtotal-price {
    color: $red-dark;
    font-weight: 600;
  }

  .dealmed-header__cart-view,
  .dealmed-header__cart-checkout {
    flex: 1;
    border-radius: 8px;
    text-align: center;
    font-weight: 700;
    padding: 12px 0;
  }

  .dealmed-header__cart-view {
    margin-right: 0.25rem;
    color: $red-dark;
    background-color: #ffe3e3;

    &:hover,
    &:focus {
      color: $white;
      background-color: $red-dark;
    }
  }

  .dealmed-header__cart-checkout {
    color: $white;
    background-color: $red-dark;

    &:hover,
    &:focus {
      color: $white;
      background-color: $grey-dark;
      border-color: $grey-dark;
    }
  }

  .dealmed-header__bottom {
    border-top: 1px solid #ccc;

    .dealmed-container {
      padding-bottom: 0;
    }
  }

  .dealmed-header__navigation {
    margin-right: 0;
  }
}

@media only screen and (min-width: 110em) {
  .dealmed-header__cart-popover {
    .dealmed-popover__body {
      // left: 50%;
      right: 0;

      &::before {
        left: 50%;
      }

      &::after {
        left: 50%;
      }
    }
  }
}
.dealmed-header__notification-list-wrapper {
  &--notification-up {
    position: absolute;
    width: 16px;
    height: 8px;
    top: -8px;
    right: 16px;
  }
}

.dealmed-header__notification-header {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e4e7e8;
  background-color: white;
  border-radius: 10px 10px 0 0;

  &__text {
    font-family: campton;
    color: $black;
    font-weight: 600;
    font-size: 18px;
    line-height: 23.04px;
  }
}

.dealmed-header__notification-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 350px;
  transform: background-color 0.2s ease;
  &__icon {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 60px;
    margin: 0 auto;
  }
  &__dot-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__dot {
    font-size: 36px;
  }

  &__message-div {
    // margin-right: 12px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    color: $black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.72px;
    color: #2f3537;
    &__title {
      font-family: campton;
      color: $black;
      line-height: 17.92px;
      font-weight: 500;
      padding-bottom: 6px;
    }
  }
}

.dealmed-header__notification-item {
  &:not(:last-child) {
    margin-bottom: 0.75rem;
    padding-bottom: 0.75rem;
  }
  &:hover {
    background-color: #f4f5f6;
  }
}

.dealmed-header__notification-list {
  margin-bottom: 0;
  padding: 1rem 0;
  min-height: 3rem;
  max-height: 500px;
  overflow-y: auto;
}

.dealmed-header__notification-empty {
  margin: 1rem 0;
  text-align: center;
  font-family: 'IBM Plex Sans';
  color: #7e7c78;
}
