@import './global_variables';
@import './global_screens';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import '~pretty-checkbox/src/scss/_variables';
@import '~pretty-checkbox/src/scss/core';
@import '~pretty-checkbox/src/pretty-checkbox.scss';

body {
  font-family: Roboto, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  background-color: $main-background !important;
  &.new-layout {
    background-color: #fff !important;
  }
}
.tooltip-container-new-design {
  .tooltip-inner.tooltip-container-inner-new-design {
    font-family: $font-manrope;
    font-size: 12px;
    background-color: $DarkBlue-800;
    border-radius: 8px;
    padding: 8px 16px;
    p {
      margin: 0;
    }
  }
  .arrow::before {
    border-width: 10px 10px 0 !important;
    border-top-color: $DarkBlue-800 !important;
  }
}

#container-wrap {
  min-height: 750px;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#container-wrap {
  width: 100%;
  max-width: none;
  padding: 0 0 0 0 !important;
}

#root {
  position: relative !important;
  min-height: 100vh !important;
  overflow: hidden;
}

.relative {
  position: relative !important;
}

.underline {
  text-decoration: underline;
}

ul,
ul li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

.react-datepicker-popper {
  z-index: 66 !important;
}

/////////////////
/////  SELECT
/////////////////
.select-arrow {
  border-color: $color-primary transparent transparent !important;
  border-width: 17px 12px 0 !important;
  right: 10px !important;
  top: 53% !important;
  transform: translateY(-50%);
}

.select-root.is-open .select-arrow {
  border-width: 0 12px 17px !important;
  border-color: transparent transparent $color-primary !important;
}

.select {
  font-size: 1em !important;
  color: $color-text-black !important;
  border: 2px solid $color-primary !important;
  min-width: 100% !important;
}

.select-placeholder {
  height: 100%;
}

.select-gray {
  .select {
    // min-width: 100% !important;
    border: 1px solid #ccc !important;
  }
  .select-arrow {
    border-color: #999 transparent transparent !important;
    border-width: 11px 11px 0 !important;
  }
  &.select-root.is-open .select-arrow {
    border-width: 0 11px 11px !important;
    border-bottom-color: #999 !important;
  }
}

///////////////////
.cursor-hover {
  cursor: pointer;
}

//Make any div overflow by
.scroll-200 {
  max-height: 200px;
  overflow-y: scroll;
}

.scroll-300 {
  max-height: 300px;
  overflow-y: scroll;
}

.scroll-500 {
  max-height: 500px;
  overflow-y: scroll;
}

.scroll-700 {
  max-height: 700px;
  overflow-y: scroll;
}

//Font size classes
.fs12px {
  font-size: 12px !important;
}

.fs15px {
  font-size: 15px !important;
}

.fs18px {
  font-size: 18px !important;
}

.fs20px {
  font-size: 20px !important;
}

.fs24px {
  font-size: 24px !important;
}

.fs36px {
  font-size: 36px !important;
}

.fs42px {
  font-size: 42px !important;
}
.price {
  color: $color-price;
  font-weight: bold;
}
.dealmed-red {
  color: $color-primary;
}
.uom-select {
  min-width: 118px;
  margin-top: 15px;
  .select {
    min-width: 0 !important;
    padding: 5px 24px 5px 8px;
    font-size: 15px !important;
    color: $color-text-black !important;
  }
  .select-arrow {
    border-width: 8px 8px 0 !important;
  }
  &.select-root.is-open .select-arrow {
    border-width: 0 8px 8px !important;
  }
  @media (max-width: $screen-lg-min) {
    margin-top: 0;
  }
  @media (min-width: $screen-xs-max) and (max-width: $screen-xl-min) {
    .select {
      padding: 6px 24px 6px 10px;
      font-size: 1em !important;
      color: $color-text-black !important;
    }
    .select-arrow {
      border-width: 8px 8px 0 !important;
    }
  }
}

.password-helper-card {
  background-color: #f8f8f8 !important;
  padding: 5px;
}

///////////////////
// PRETTY CHECKBOX (FOR PRODUCT FILTER)
// JASON NEVER EVER EVER EVER OVERRITE ROOT CLASSES OF A COMPONENT LIBRARY LIKE THIS!!!!!!!!!!
// VERY BAD CONVENTION, I EXPECTED MORE
// MAYER I AM SORRY. YOU ARE RIGHT, AND THANKS FOR POINTING IT OUT.
// IN THE FUTURE I WILL BE MORE CAREFUL
///////////////////
//ML Changed from .pretty to pretty-product-filter
.pretty-product-filter input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
  background-color: $color-primary-bright !important;
  border: 2px solid transparent;
}

//ML Changed from .pretty to pretty-product-filter
.pretty-product-filter {
  display: block;
  margin-bottom: 18px;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 16px;
  }
}

/*------------------------------------------------------------------
  [BOOTSTRAP THEME]
*/

// Brand Colors
$theme-colors: (
  'primary': $color-primary,
  'secondary': $color-secondary,
  'danger': $color-primary-danger,
  'danger-light': $color-primary-danger-light,
  'gray': rgb(92, 105, 105),
  'white': #fff,
);

//Button
$btn-border-radius: 2px;
$btn-border-radius-lg: 2px;
$btn-border-radius-sm: 0px;
// Components
$border-radius: 0.1rem;
$border-radius-lg: 0.1rem;
$border-radius-sm: 0.1rem;
/*------------------------------------------------------------------
  [TOASTIFY]
*/

.toastify-red-bar {
  background-color: $color-primary !important;
}

.toastify-green-bar {
  background-color: #28a745;
}

.toastify-gray-bar {
  background-color: #6c757d;
}

.toastify-body {
  color: rgb(255, 255, 255) !important;
}

.toastify-progress {
  background-color: #e0e0e0 !important;
}

/*------------------------------------------------------------------
  [Modals]
*/

.btn-loader:before {
  margin-right: 9px !important;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}

.j-switch label {
  display: flex;
  margin-bottom: 19px;
  width: 86%;
  justify-content: space-between;
  margin: 11px auto;
  font-size: 1.1em;
  color: #5d5d5d;
  border-bottom: 1px solid #c7c7c794;
  padding-bottom: 12px;
  padding-top: 12px;
}

.fill-field-warning {
  font-size: 16px;
  color: $color-delete;
  font-weight: bold;
  @media (max-width: $screen-xs-max) {
    font-size: 14px;
    line-height: 16px;
  }
}

.save-btn {
  background-color: $color-save-green !important;
  border: 1px solid $color-save-green !important;
  &:hover {
    background-color: $color-save-green-hover !important;
    border: 1px solid $color-save-green-hover !important;
  }
}

.delete-btn {
  background-color: $color-delete !important;
  border: 1px solid $color-delete !important;
  &:hover {
    background-color: $color-delete-hover !important;
    border: 1px solid $color-delete-hover !important;
  }
}

.modal-dialog {
  max-width: 1050px;
}

.modal-body {
  padding: 20px 0 0 0 !important;
}

.modal-footer {
  padding-right: 25px;
  padding-left: 25px;
  border-radius: 0 0 5px 5px;
}

.modal-head {
  padding: 0 30px !important;
  border-radius: 5px 5px 0px 0px;
}

.modal-header-col {
  border-left: 1px solid white;
}

.modal-head h1,
.modal-head h2,
.modal-head h3,
.modal-head h4,
.modal-head h5,
.modal-head h6 {
  display: block;
  font-size: 1.5em;
  padding: 1em 0 0.5em 0;
  .mdi {
    margin-right: 15px;
    font-size: 30px;
    position: relative;
    top: 1px;
  }
}

.modal-head h1 {
  padding: 10px 0;
  margin-bottom: 0 !important;
}

.modal-full-color .modal-header {
  padding: 19px;
}

.modal-full-color .modal-content {
  color: #ffffff;
  background-color: transparent;
}

.modal-full-color .modal-content .close {
  color: #ffffff;
  line-height: 40px;
  opacity: 0.6;
}

.modal-full-color .modal-content .close:hover {
  opacity: 1;
}

.modal-full-color .modal-content .modal-footer {
  border: none;
}

.modal-full-color .btn-default {
  border-width: 2px;
  border-color: transparent;
}

.modal-full-color .btn-primary,
.modal-full-color .btn-success,
.modal-full-color .btn-warning,
.modal-full-color .btn-danger {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
  border-width: 2px;
}

.modal-full-color .btn-primary:focus,
.modal-full-color .btn-success:focus,
.modal-full-color .btn-warning:focus,
.modal-full-color .btn-danger:focus {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.4);
}

.modal-full-color .btn-primary:hover,
.modal-full-color .btn-success:hover,
.modal-full-color .btn-warning:hover,
.modal-full-color .btn-danger:hover,
.modal-full-color .btn-primary .hover,
.modal-full-color .btn-success .hover,
.modal-full-color .btn-warning .hover,
.modal-full-color .btn-danger .hover {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.8);
}

.modal-full-color .btn-primary:active,
.modal-full-color .btn-success:active,
.modal-full-color .btn-warning:active,
.modal-full-color .btn-danger:active {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.8);
}

.modal-full-color-success .modal-header {
  background-color: #37b358;
}

.modal-full-color-success .modal-body,
.modal-full-color-success .modal-footer {
  background-color: #37b358;
}

.modal-full-color-success .btn-default {
  color: #37b358;
}

.modal-full-color-success .btn-default:active {
  color: #37b358;
}

.modal-full-color-primary .modal-header {
  background-color: #5f99f5;
}

.modal-full-color-primary .modal-body,
.modal-full-color-primary .modal-footer {
  background-color: #5f99f5;
}

.modal-full-color-primary .btn-default {
  color: #5f99f5;
}

.modal-full-color-primary .btn-default:active {
  color: #5f99f5;
}

.modal-full-color-warning .modal-header {
  background-color: #f6c163;
}

.modal-full-color-warning .modal-body,
.modal-full-color-warning .modal-footer {
  background-color: #f6c163;
}

.modal-full-color-warning .btn-default {
  color: #f6c163;
}

.modal-full-color-warning .btn-default:active {
  color: #f6c163;
}

.modal-full-color-danger .modal-header {
  background-color: #eb6357;
}

.modal-full-color-danger .modal-body,
.modal-full-color-danger .modal-footer {
  background-color: #eb6357;
}

.modal-full-color-danger .btn-default {
  color: #eb6357;
}

.modal-full-color-danger .btn-default:active {
  color: #eb6357;
}

.modal-full-color-dark .modal-header {
  background-color: #3d3c3c;
}

.modal-full-color-dark .modal-body,
.modal-full-color-dark .modal-footer {
  background-color: #3d3c3c;
}

.modal-full-color-dark .btn-default {
  color: #3d3c3c;
}

.modal-full-color-dark .btn-default:active {
  color: #3d3c3c;
}

.full-width {
  width: 90%;
  max-width: none;
}

.colored-header .modal-content .modal-header {
  background-color: #e6e6e6;
  color: #ffffff;
  padding: 30px 20px;
}

.colored-header .modal-content .modal-header .modal-title {
  font-size: 20px;
}

.colored-header .modal-content .modal-header .close {
  color: rgba(0, 0, 0, 0.7);
  margin-top: 2px;
}

.colored-header .modal-content .modal-body {
  padding: 35px 20px 20px;
  background-color: #ffffff;
  color: #404040;
}

.colored-header .modal-content .modal-footer {
  background-color: #ffffff;
}

.colored-header-success .modal-content .modal-head {
  background-color: #37b358;
}

.colored-header-primary .modal-content .modal-head {
  background-color: white;
  text-align: center;
  color: $color-primary-bright;
}

.colored-header-warning .modal-content .modal-head {
  background-color: #f6c163;
}

.colored-header-danger .modal-content .modal-head {
  background-color: #eb6357;
}

.colored-header-dark .modal-content .modal-head {
  background-color: #3d3c3c;
}

@media (max-width: 767px) {
  .modal-dialog {
    min-width: 100%;
    margin: 0px !important;
    padding: 2px 2px 0 2px;
  }
}

.be-modal-open {
  overflow-x: visible;
}

// [Cards]
.card-heading-container {
  border-top: $color-primary 3px solid;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #e9e9e9;
}

.card {
  background-color: #ffffff;
  margin-bottom: 25px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  border-radius: 1px;
  border: none !important;
}

.card-body {
  padding: 8px 20px 20px;
  border-radius: 0 0 3px 3px;
}

.card-heading {
  padding: 2px 5px;
  margin: 20px 20px;
}

.card-table .card-body {
  padding: 0;
}

@media (max-width: 576px) {
  .card-heading {
    padding: 0;
  }
}

//Paginator
.pagination {
  @media (max-width: 1200px) {
    margin-top: 20px !important;
  }
}

.paginator {
  .page-item.active .page-link {
    border-color: $color-primary !important;
    background-color: $color-primary !important;
    color: white !important;
  }
  .page-link {
    color: $color-primary !important;
    font-size: 18px !important;
  }
}

/* Scroll */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--textlight);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #bebebe;
}

// AE STYLES
.dealmed-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  max-width: 120rem;
}

/*------------------------------------------------------------------
  [NEW LAYOUT]
*/
.button.new-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $Scarlet-900;
  color: $white;
  border: none;
  border-radius: 8px;
  padding: 8px 12px 8px 16px;
  width: 100%;
  height: 40px;
  font-family: $font-manrope;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  &:hover {
    background: $red-button-linear-red-bckgrnd-hover;
  }
}
