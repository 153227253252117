.dealmed-main-navigation {
  font-family: 'IBM Plex Sans' !important;

  &__btn {
    &:hover,
    &:active {
      border-bottom: 2px solid #a42e49;
      color: #a42e49;
      height: 38px;
    }
  }

  &__btn,
  &__link {
    font-size: 12px;
    font-weight: 600;
    padding: 10px 34px;
    color: #5e6a6e;
    white-space: nowrap;
    border: none;
    font-weight: 600;
    background-color: transparent;
    text-align: left;
    @media (min-width: 1024px) and (max-width: 1439px) {
      padding: 10px 24px;
    }
  }

  &__item {
    // &.isSelected {
    //   & .dealmed-main-navigation__btn {
    //     background-color: #F4F3F1;
    //     color: $red-dark;
    //   }
    // }
    display: flex;
    flex-direction: column;
  }

  &__list {
    display: flex;
    justify-content: center;
  }

  &__submenu {
    display: flex !important;
    visibility: visible !important;
    position: relative !important;

    & .dealmed-main-navigation__link {
      font-size: 12px;
      font-weight: 400;
      color: #0c0d0e;

      &:hover {
        text-decoration: underline !important;
      }
    }
    & button.dealmed-main-navigation__btn-close {
      display: none;
    }
  }

  &__menu {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translateX(100%);
    top: 42px;
    left: 0;
    background-color: white;
    // transition: transform 300ms ease-in-out;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 9999;
    width: 100%;
    padding: 28px 0 36px 0;

    &--expanded {
      transform: translateX(0);
      border-bottom: 1px solid #e4e7e8;
      box-shadow: 0px 6px 26.4px 0px rgba(0, 0, 0, 0.06);
    }

    &--visible {
      visibility: visible;
      opacity: 1;

      & > .dealmed-main-navigation__list > .dealmed-main-navigation__item {
        @media (min-width: 1024px) and (max-width: 1439px) {
          border-right: 1px solid #e4e7e8;
        }
      }
      & > .dealmed-main-navigation__list > .dealmed-main-navigation__item:last-child {
        @media (min-width: 1024px) and (max-width: 1439px) {
          border-right: none;
        }
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      overflow-y: auto;
      height: 100%;
    }
    @media (min-width: 768px) {
      // Apply this style only if the screen width is greater than the mobile breakpoint
      &--overflow {
        overflow-y: auto;
      }
    }
    & .dealmed-main-navigation__btn {
      height: unset;
      &:hover {
        border-bottom: none;
      }
      &-close {
        width: 12px;
        height: 12px;
      }
    }
    & .dealmed-main-navigation__btn-text {
      font-size: 14px;
      color: #0c0d0e;
      display: inline-block;
      max-width: 200px;
      white-space: normal;
      // Removed this so parents don't have underline on hover
      // &:hover {
      // text-decoration: underline !important;
      // }
    }
    & .dealmed-main-navigation__btn-close {
      width: 28px;
      height: 28px;
      position: absolute;
      right: 12px;
      top: 12px;
      border: none;
      border-radius: 4px;
      background-color: unset;
      display: flex;
      align-items: center;
      &:hover {
        background: #e4e7e8;
      }
    }
  }
}

// & .dealmed-main-navigation__link {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 20px;
//   border-radius: 8px;
//   color: black;
//   font-size: 18px;
//   white-space: nowrap;
//   border:none;
//   font-weight:500;
//   background-color:transparent;
//   gap: 4px;

//   @media (max-width: 1280px) {
//     padding: 10px 10px;
//     font-size: 16px;

//   }

//   &:hover {
//     background-color: #F4F3F1;
//   }

//   & svg {
//     width:14px;
//     height:14px;
//   }
// }

.dealmed-main-navigation__toggle-btn {
  display: flex;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: $grey-dark;

  &:hover,
  &:focus {
    color: $red-dark;
  }
}

.dealmed-main-navigation__toggle-btn-icon {
  width: 24px;
  height: 24px;
}

// mobile
.dealmed-main-navigation__wrapper {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 9999;

  &--expanded {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
    }
    & .dealmed-main-navigation__menu {
      position: relative;
      transform: translateX(0);
      padding: 0;
      height: 0;
      &--expanded {
        height: 100%;
      }
    }

    & .dealmed-main-navigation__list {
      flex-direction: column;
      padding: 0 12px;
    }

    & .dealmed-main-navigation__item {
      border-bottom: 1px solid #e4e7e8;
    }
    & .dealmed-main-navigation__btn,
    .dealmed-main-navigation__link {
      background: transparent;
      border: none;
      height: 60px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    & .dealmed-main-navigation__btn-text,
    .dealmed-main-navigation__link {
      font-size: 16px;
      color: #0c0d0e;
    }
    & .dealmed-main-navigation__btn-text {
      text-align: left;
    }
    & .dealmed-main-navigation__account-icon {
      display: none;
    }
    & .dealmed-main-navigation__account-name {
      margin: 0;
      color: black;
    }
    & .dealmed-main-navigation__account-top {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 300px;
      color: $grey-dark;
    }
    & .dealmed-main-navigation__account-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 12px 0;
      font-size: 18px;

      & .dealmed-main-navigation__account-link {
        color: #0c0d0e;
        border-right: 1px solid #d6dadc;
        font-size: 14px;
        &:last-child {
          border-right: none;
        }
      }
      & .dealmed-main-navigation__account-logout {
        font-weight: 800;
        background-color: transparent;
        border: none;
      }
    }
    & .dealmed-main-navigation__account-related {
      display: flex;
      flex-direction: column;
      width: 100%;

      & label {
        font-size: 12px;
      }
    }
    .dealmed-main-navigation__submenu {
      left: 0;
      padding: 0;
      border-radius: 0;
    }
  }
}

.dealmed-main-navigation__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 10px;
  border-bottom: 1px solid $grey-mid;
  &--wrapper {
    display: flex;
    padding: 0 6px 12px 6px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.dealmed-main-navigation__logo-icon {
  width: 12rem;
  height: 4rem;
}

.dealmed-main-navigation__account {
  padding-left: 6px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.dealmed-main-navigation__account-link {
  color: #0c0d0e;
  font-weight: 500;
  font-size: 14px;
  text-decoration: underline;
  &--svg {
    color: $red-dark;
    width: 17px;
    height: 17px;
    margin-right: 4px;
  }
  &--logged {
    text-decoration: underline;
    color: $red-dark;
    font-weight: 500;
    font-size: 14px;
    padding-right: 15px;
    border-right: 1px solid #d6dadc;
    line-height: 17.92px;

    &.active {
      text-decoration: underline;
      color: $red-dark;
      font-weight: 500;
    }
  }
  &--btn {
    color: #0c0d0e;
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    &.active {
      text-decoration: underline;
      color: #0c0d0e;
    }
    &:not(:last-child) {
      margin-right: 1rem;
      padding-right: 1rem;
      border-right: 1px solid #d6dadc;
    }
  }
  &:not(:last-child) {
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1px solid #d6dadc;
  }
  &:hover,
  &:focus {
    color: $grey-dark;
  }
}

.dealmed-main-navigation__close-btn {
  display: flex;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: $grey-dark;

  &:hover,
  &:focus {
    color: $red-dark;
  }
}

.dealmed-main-navigation__body {
  position: relative;
  flex: 1;
}

//mobile submenus

@media (max-width: 767px) {
  /* Adjust the max-width value based on your mobile breakpoint */
  .dealmed-main-navigation__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }
}

.dealmed-main-navigation__back-btn {
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 20px;
  width: 100%;
  color: #7e7c78;
  background-color: #f4f3f1;
  border: none;
  border-radius: 0;
  gap: 8px;
}

.dealmed-main-navigation__back-btn-icon-wrapper {
  margin-right: 0.625rem;
}

@media only screen and (min-width: 62em) {
  .dealmed-main-navigation__toggle-btn,
  .dealmed-main-navigation__header {
    display: none;
  }

  .dealmed-main-navigation__wrapper {
    display: block;
    position: static;
  }

  .dealmed-main-navigation__body {
    & > .dealmed-main-navigation__list {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: row;
      gap: 20px;
      margin-bottom: 0;

      @media (max-width: 1280px) {
        gap: 8px;
      }
    }
  }

  .dealmed-main-navigation__btn-icon {
    transform: rotate(90deg);
  }

  // first level submenu on desktop

  .dealmed-main-navigation__submenu {
    // top: 64px;
    // left: 0;
    // bottom: unset;
    // transform: translateX(0);
    // overflow: visible;
    // border-radius: 12px;
    // padding:8px ;

    & .dealmed-main-navigation__list {
      display: flex;
      flex-direction: column;
      // gap:2px;
    }

    & .dealmed-main-navigation__link {
      & .dealmed-main-navigation__btn-text {
        white-space: nowrap;
      }

      & svg {
        width: 12px;
        height: 12px;
      }
    }

    .dealmed-main-navigation__btn-icon {
      transform: none;
    }
  }

  // second level submenu on desktop

  // .dealmed-main-navigation__submenu {
  //   top: 0;
  //   width: 100%;
  //   left: 8px;
  //   background: rgba(244, 243, 241, 1);
  //   // transition:none;
  //   display:none;
  //   &--expanded {
  //     transform: translateX(100%);
  //     display:block;
  //   }
  //   & .dealmed-main-navigation__link {
  //     &:hover {
  //       background-color: white;
  //       color: $red-dark;
  //     }
  //   }
  //   .dealmed-main-navigation__btn-icon {
  //     transform: none;
  //   }
  // }

  .dealmed-main-navigation__back-btn {
    display: none;
  }
  // This is just to get the grandparent and grandparent only to have the active styles
  // For a grandparent to be active, in the MainNavigationMenu component, isCurrentlyActive must be true and depth must be 0
  .dealmed-main-navigation__btn--grandparent-active {
    border-bottom: 2px solid #a42e49;
    color: #a42e49;
    height: 38px;
  }
}
