@import '../../assets/CSS/globals';

.product-details {
  [data-reach-dialog-overlay] {
    background: rgba(0, 0, 0, 0.8);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
  }

  [data-reach-dialog-content] {
    display: flex;
    position: absolute;
    top: 30%;
    left: 50%;
    padding: 25px;
    width: 100% !important;
    max-width: 600px;
    min-height: 400px;
    background-color: #ffffff;
    border-radius: 8px;
    transform: translate(-50%, -50%);
  }

  &__container {
    margin: 0 auto;
    padding: 1rem 1rem 4rem;
    max-width: 90rem;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__breadcrumbs {
    align-self: flex-start;
    margin-bottom: 1rem;
  }

  &__assets {
    order: 1;
    margin-bottom: 3rem;
  }

  &__product {
    order: 2;
    margin-bottom: 2rem;
    width: 100%;
  }

  &__description {
    order: 3;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #f2f3f7;
    border: 1px solid #dddee2;
    border-radius: 4px;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__product-image,
  &__thumb-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &__product-image {
    margin-bottom: 0.5rem;
    width: 300px;
    height: 300px;
  }

  &__thumbs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;
    gap: 6px;
    overflow: auto;
  }

  &__thumb {
    flex-shrink: 0;
  }

  &__thumb-button {
    display: flex;
    position: relative;
    margin: 0;
    padding: 0.25rem;
    background-color: transparent;
    border: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

    &--selected {
      &::before {
        border: 1px solid #b92a47;
      }
    }

    &:hover,
    &:focus {
      outline: none;

      &::before {
        border: 1px solid #b92a47;
      }
    }
  }

  &__thumb-image {
    width: 45px;
    height: 45px;
    opacity: 0.5;

    &--selected {
      opacity: 1;
    }
  }

  &__box {
    padding: 25px;
    box-shadow: 0 0 25px #ddd;
  }

  &__header {
    margin-bottom: 1.5rem;
  }

  &__product-name {
    margin-bottom: 0.5rem;
    font-size: 1.325rem;
    font-weight: 700;
  }

  &__header-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem 1.25rem;
  }

  &__product-manufacturer {
    margin: 0;
    flex-basis: 100%;
    width: 100%;
    color: #b92a47;
    font-weight: 600;
  }

  &__product-id {
    margin: 0;
    color: #7d7f8a;
  }

  &__product-mpn {
    margin: 0;
    color: #7d7f8a;
  }

  &__availability {
    display: flex;
    align-items: center;
  }

  &__availability-icon-wrapper {
    display: flex;
    margin-right: 0.25rem;
  }

  &__availability-icon {
    &.dealmed-icon {
      width: 20px;
      height: 20px;
    }

    &--available {
      &.dealmed-icon {
        fill: #58c550;
      }
    }

    &--unavailable {
      &.dealmed-icon {
        fill: #b92a47;
      }
    }
  }

  &__availability-text {
    margin-bottom: 0;
    font-weight: 600;

    &--available {
      color: #58c550;
    }

    &--unavailable {
      color: #b92a47;
    }
  }

  &__rx-icon {
    &.dealmed-icon {
      width: 24px;
      height: 24px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    background-color: #f2f3f7;
    border-radius: 4px;
  }

  &__info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80px;
  }

  &__login-button {
    margin: 0 1rem;
    padding: 1em 1.5em;
    width: 100%;
    background-color: #b92a47;
    color: #ffffff;
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    transition: background-color 150ms ease-in-out;

    &:hover,
    &:focus {
      background-color: #000000;
      color: #ffffff;
    }
  }

  &__info-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
  }

  &__qty-type,
  &__matrix-dropdown {
    // margin-bottom: 0.75rem;
    width: 100%;
    max-width: 250px;
  }

  &__quantity {
    margin-bottom: 1rem;
    max-width: 250px;
  }

  &__quantity-label {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.5rem;
    min-height: 75px;
    background-color: #ffffff;
    border: 1px solid #dddee2;
    border-radius: 4px;
    transition: border-color 150ms ease-in-out;

    &:focus-within {
      border-color: #b92a47;
    }
  }

  &__quantity-decrement,
  &__quantity-increment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 1em;
    min-height: 100%;
    background-color: #f9eeeb;
    border: none;
    border-radius: 4px;
    transition: background-color 150ms ease-in-out;

    &:hover,
    &:focus {
      background-color: #b92a47;

      .product-details__quantity-icon {
        &.dealmed-icon {
          fill: #ffffff;
        }
      }
    }
  }

  &__quantity-input {
    padding: 0 0.25rem;
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: center;
    font-weight: 700;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      display: none;
    }

    &:hover,
    &:focus {
      outline: none;
    }
  }

  &__quantity-icon {
    &.dealmed-icon {
      width: 24px;
      height: 24px;
      fill: #b92a47;
      transition: fill 150ms ease-in-out;
    }
  }

  &__info-invalid {
    margin: 0;
    color: #b92a47;
    text-align: center;
    font-weight: 600;
  }

  &__info-price {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
  }

  &__info-add {
    // width: 100%;
  }

  &__add-to-cart {
    position: relative;
    padding: 0.75em 1.25em;
    width: 100%;
    color: #ffffff;
    background-color: #b92a47;
    border: none;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 150ms ease-in-out;

    &:hover,
    &:focus {
      background-color: #000000;
    }
  }

  &__add-to-cart-icon {
    display: inline-block;
    margin-right: 0.5rem;
    width: 16px;
    height: 16px;
    fill: #ffffff;
  }

  &__add-to-cart-message,
  &__add-to-cart-number {
    color: #ed2525;
    font-weight: 600;
    font-size: 14px;
  }

  &__add-to-cart-number {
    text-decoration: underline !important;
    transition: color 150ms ease-in-out;

    &:hover,
    &:focus {
      color: #000000;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  &__action-open {
    position: relative;
    margin: 0;
    padding: 0;
    width: 48px;
    min-height: 48px;
    background-color: #f5f3f1;
    border: none;
    border-radius: 4px;
    transition: background-color 150ms ease-in-out;
    margin-left: 7px;
    & svg {
      fill: #9b9191;
      width: 24px;
    }
    &:hover,
    &:focus {
      background-color: #9b9191;
      .product-details__action-tooltip {
        display: block;
      }
      .product-details__action-icon {
        fill: #ffffff;
      }
      & svg {
        fill: white;
      }
    }
  }

  &__action-tooltip {
    display: none;
    position: absolute;
    top: -5px;
    left: 50%;
    padding: 0.25rem;
    width: 115px;
    background-color: #f2f3f7;
    border: 1px solid #dddee2;
    transform: translate(-50%, calc(-100% - 3px));

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -6px;
      left: 50%;
      width: 10px;
      height: 10px;
      background-color: #f2f3f7;
      border-bottom: 1px solid #dddee2;
      border-right: 1px solid #dddee2;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  &__action-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  &__action-close {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin-bottom: 2rem;
    padding: 0;
    background-color: transparent;
    border: none;

    &:hover,
    &:focus {
      outline: none;

      .product-details__action-icon {
        fill: #b92a47;
      }
    }
  }

  &__action-header {
    margin-bottom: 2rem;
  }

  &__action-phone {
    display: flex;
    align-items: center;
    margin-bottom: 5rem;
    color: #000000;
    font-size: 1.25rem;
    transition: color 150ms ease-in-out;

    &:hover,
    &:focus {
      color: #b92a47;

      .product-details__action-icon {
        fill: #b92a47;
      }
    }
  }

  &__action-icon-wrapper {
    margin-right: 0.5rem;
  }

  &__action-icon {
    &.dealmed-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      transition: fill 150ms ease-in-out;
    }

    &--red {
      &.dealmed-icon {
        width: 32px;
        height: 32px;
        fill: #b92a47;
      }
    }

    &--close {
      &.dealmed-icon {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__action-contact {
    padding: 0.75em 1.75em;
    background-color: #b92a47;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1.25rem;
    transition: background-color 150ms ease-in-out;

    &:hover,
    &:focus {
      background-color: #000000;
    }
  }

  &__action-share {
    display: flex;
    align-items: center;
  }

  &__action-share-item {
    margin: 0 0.75rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    .react-share__ShareButton {
      i {
        font-size: 2rem;
      }

      i::before {
        transition: color 150ms ease-in-out;
      }

      &:hover,
      &:focus {
        outline: none;

        i::before {
          color: #b92a47;
        }
      }
    }
  }

  &__action-copy {
    width: 100%;
  }

  &__action-copy-label {
    display: flex;
    flex-direction: column;
  }

  &__action-copy-input {
    padding: 0 0.5rem;
    min-height: 45px;
    border: 1px solid hsla(0, 0%, 0%, 0.2);
    border-radius: 4px;
  }

  &__action-copy-button {
    padding: 0.5em 1.25em;
    background-color: #b92a47;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    transition: background-color 150ms ease-in-out;

    &:hover,
    &:focus {
      background-color: #000000;
    }
  }

  &__tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 5px;
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
    min-height: 50px;
    background-color: #f2f3f7;
    border-radius: 4px;
    z-index: 2;
  }

  &__tabs-list {
    display: flex;
    align-items: center;
    margin: 0;
  }

  &__tabs-item {
    display: flex;
    align-items: center;
    margin: 0 0.25rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__tabs-link {
    position: relative;
    color: #000000;
    transition: color 150ms ease-in-out;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      left: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      visibility: hidden;
      opacity: 0;
      background-color: #b92a47;
      border-radius: 4px;
      transition: visibility 150ms ease-in-out, opacity 150ms ease-in-out;
      z-index: -1;
    }

    &:hover,
    &:focus {
      color: #ffffff;

      &::before {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__tabs-text {
    margin: 0;
    font-size: 0.875rem;
  }

  &__tab-title {
    scroll-margin-top: 5rem;
  }

  &__downloads {
    margin-bottom: 2rem;
  }

  &__downloads-list {
    margin: -1rem -16px 0;

    &--max {
      margin: -1rem -20px 0;
    }
  }

  &__downloads-track {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }

  &__download-slide {
    position: relative;
    max-width: 300px;
    background-color: #f2f3f7;
    border-radius: 4px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 135px;
      height: 135px;
      background-color: #e0e0e0;
      border-radius: 4px 45% 50% 45%;
    }
  }

  &__download-link {
    display: flex;
    align-items: flex-end;
    position: relative;
    min-height: 275px;
    background-position: 0 0;
    background-size: 50%;
    background-repeat: no-repeat;
    z-index: 2;

    &:hover,
    &:focus {
      .product-details__download-icon {
        .dealmed-icon {
          fill: #000000;
        }
      }

      .product-details__download-text {
        color: #000000;
      }
    }
  }

  &__download-content {
    padding: 25px;
  }

  &__download-title {
    color: #000000;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 700;
  }

  &__download-wrapper {
    display: flex;
    align-items: center;
  }

  &__download-icon {
    &.dealmed-icon {
      display: inline-block;
      flex-shrink: 0;
      margin-right: 0.5rem;
      width: 20px;
      height: 20px;
      fill: #b92a47;
      transition: fill 150ms ease-in-out;
    }
  }

  &__download-text {
    margin: 0;
    color: #b92a47;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
    transition: color 150ms ease-in-out;
  }

  &__related-product-image,
  &__alternative-product-image {
    max-width: 100%;
    max-height: 100%;
  }

  &__related-products {
    margin-bottom: 2rem;
  }

  &__related-products-list {
    margin: -1rem -16px 0;

    &--max {
      margin: -1rem -20px 0;
    }
  }

  &__related-products-track {
    padding-bottom: 1.5rem;
  }

  &__related-product-slide {
    padding-top: 2rem;
  }

  &__related-product-link {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 25px;
    min-height: 465px;
    border: 1px solid #dddee2;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      background-color: #b92a47;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform 150ms ease-in-out;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: box-shadow 150ms ease-in-out;
    }

    &:hover,
    &:focus {
      outline: none;

      &::before {
        transform: scaleY(1);
      }

      &::after {
        box-shadow: 0px 0px 20px hsla(0, 0%, 0%, 0.3);
      }
    }
  }

  &__related-product-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.75rem;
  }

  &__related-product-image {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
  }

  &__related-product-manufacturer {
    margin: 0 0 0.5rem;
    color: #b92a47;
  }

  &__related-product-name {
    margin: 0;
    color: #000000;
  }

  &__alternative-products-list {
    margin: -1rem -16px 0;

    &--max {
      margin: -1rem -20px 0;
    }

    &--alternate {
      .product-details__alternative-product-link {
        flex-direction: row;
        padding: 0;
        width: 100%;
        min-height: 0;
        border: none;

        &::before,
        &::after {
          content: none;
        }

        &:hover,
        &:focus {
          .product-details__alternative-product-image {
            border-color: #b92a47;
            box-shadow: 0px 0px 20px hsla(0, 0%, 0%, 0.3);
          }
        }
      }

      .product-details__alternative-product-image-wrapper {
        flex-shrink: 0;
        margin-bottom: 0;
        margin-right: 1rem;
      }

      .product-details__alternative-product-image {
        width: 200px;
        height: 200px;
        border: 1px solid #dddee2;
        transition: border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
      }

      .product-details__alternative-product-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
      }

      .product-details__alternative-product-manufacturer,
      .product-details__alternative-product-name,
      .product-details__alternative-product-price {
        flex-basis: 100%;
      }

      .product-details__alternative-product-login,
      .product-details__alternative-product-price {
        align-self: flex-end;
        margin-bottom: 2rem;
      }
    }
  }

  &__alternative-products-track {
    padding-bottom: 1.5rem;
  }

  &__alternative-product-slide {
    padding-top: 2rem;
  }

  &__alternative-product-link {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 25px;
    min-height: 465px;
    border: 1px solid #dddee2;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      background-color: #b92a47;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform 150ms ease-in-out;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: box-shadow 150ms ease-in-out;
    }

    &:hover,
    &:focus {
      outline: none;

      &::before {
        transform: scaleY(1);
      }

      &::after {
        box-shadow: 0px 0px 20px hsla(0, 0%, 0%, 0.3);
      }
    }
  }

  &__alternative-product-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.75rem;
  }

  &__alternative-product-image {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
  }

  &__alternative-product-manufacturer {
    margin: 0 0 0.5rem;
    color: #7d7f8a;
  }

  &__alternative-product-name {
    margin: 0 0 0.5rem;
    color: #000000;
  }

  &__alternative-product-login,
  &__alternative-product-price {
    margin: 0;
    color: #b92a47;
  }
}

@media only screen and (min-width: 32em) {
  .product-details {
    &__actions {
      justify-content: flex-start;
    }

    &__action {
      margin-right: 1rem;
    }

    &__tabs-item {
      margin: 0 1.5rem;
    }

    &__tabs-text {
      font-size: 0.95rem;
      font-weight: 700;
    }

    &__tab-title {
      margin-bottom: 1.25rem;
    }
  }
}

@media only screen and (min-width: 48em) {
  .product-details {
    &__container {
      padding-top: 3rem;
      padding-bottom: 6rem;
    }

    &__main {
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 2rem;
    }

    &__breadcrumbs {
      margin-bottom: 2rem;
    }

    &__assets {
      flex-basis: calc(60% - 0.25rem);
      margin-bottom: 0;
      margin-right: 0.25rem;
      width: 50%;
      order: unset;
    }

    &__product {
      flex-basis: 100%;
      order: unset;
    }

    &__description {
      flex-basis: calc(40% - 0.25rem);
      margin-bottom: 0;
      margin-left: 0.25rem;
      width: 50%;
      order: unset;
    }

    &__product-image {
      width: 450px;
      height: 450px;
    }

    &__info-content {
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }

    &__qty-type,
    &__matrix-dropdown {
      margin-bottom: 0;
    }

    &__quantity {
      margin-bottom: 0;
    }

    &__info-price {
      margin-bottom: 0;
    }

    &__info-add {
      // flex-basis: 100%;
      // width: 100%;
    }

    &__tabs-item {
      margin: 0 3rem;
    }

    &__tabs-text {
      font-size: 1.125rem;
    }

    &__downloads {
      margin-bottom: 3rem;
    }

    &__related-products {
      margin-bottom: 3rem;
    }

    &__related-product-image {
      width: 300px;
      height: 300px;
    }
  }
}

@media only screen and (min-width: 62em) {
  .product-details {
    &__container {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    &__breadcrumbs {
      margin-bottom: 3rem;
    }

    &__assets {
      flex-basis: calc(50% - 0.25rem);
    }

    &__description {
      flex-basis: calc(50% - 0.25rem);
    }

    &__box {
      display: flex;
    }

    &__header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      margin-bottom: 0;
    }

    &__header-info {
      gap: 0.5rem 1rem;
      max-width: 375px;
    }

    &__product-name {
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }

    &__product-manufacturer {
      flex-basis: auto;
      width: auto;
    }

    &__info {
      justify-content: center;
      flex: 2;
      margin: 0 1rem;
    }

    &__qty-type,
    &__matrix-dropdown {
      flex-basis: calc(50% - 1rem);
      max-width: 225px;
    }

    &__quantity {
      flex-basis: calc(50% - 1rem);
      max-width: 225px;
    }

    &__info-price {
      font-size: 2rem;
    }

    &__info-add {
      // flex-basis: auto;
      width: auto;
    }

    &__actions {
      flex-direction: column;
      justify-content: space-between;
    }

    &__action {
      margin-bottom: 0.5rem;
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__tabs-item {
      margin: 0 5rem;
    }

    &__downloads-list {
      margin: -1rem -48px 0;

      &--max {
        margin: -1rem -20px 0;
      }
    }

    &__related-products-list {
      margin: -1rem -48px 0;

      &--max {
        margin: -1rem -20px 0;
      }
    }

    &__alternative-products-list {
      margin: -1rem -48px 0;

      &--max {
        margin: -1rem -20px 0;
      }
    }
  }
}

@media only screen and (min-width: 64em) {
  .product-details {
    &__container {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}

@media only screen and (min-width: 75em) {
  .product-details {
    &__product-name {
      font-size: 2rem;
    }

    &__qty-type,
    &__matrix-dropdown {
      flex-basis: auto;
    }

    &__quantity {
      flex-basis: auto;
    }
  }
}

@media only screen and (min-width: 100em) {
  .product-details {
    &__container {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &__downloads-list {
      margin: -1rem -20px 0;

      &--max {
        margin: -1rem -20px 0;
      }
    }

    &__related-products-list {
      margin: -1rem -20px 0;

      &--max {
        margin: -1rem -20px 0;
      }
    }

    &__alternative-products-list {
      margin: -1rem -20px 0;

      &--max {
        margin: -1rem -20px 0;
      }
    }
  }
}

.product-details-hero-section {
  background-color: $color-background-blue-gray;
  padding: 30px 15%;
  padding-top: 70px;
  justify-content: center;

  @media (max-width: $screen-xl-min) {
    padding: 30px 1%;
  }

  @media (max-width: $screen-xs-max) {
    padding: 25px 2%;
  }
}

.productDetails {
  margin-top: 40px;
  display: block;
}

.product-details-hero {
  margin-left: 1em;
}

.product-details-hero-text-wrap {
  display: flex;
  flex-direction: column;
}

.product-details-hero-manufacturer {
  padding-left: 15px;
  margin-bottom: 11px;
  height: 27px;
  font-size: 20px;
  color: #7d7f8a;
  font-family: 'Calibri', 'Trebuchet MS', sans-serif;
  align-items: left;
}

.product-details-hero-title {
  padding-left: 15px;
  font-size: 30px;
  margin-bottom: 22px;
  color: $color-text-black;
  font-family: 'Campton', sans-serif;
  display: flex;
  align-items: left;
  font-weight: 700;
}
.product-details-hero-mpn {
  padding-left: 15px;
  height: 32px;
  margin-bottom: 37px;
  font-weight: bold;
  font-size: 26px;
  color: #7d7f8a;
  font-family: 'Calibri', 'Trebuchet MS', sans-serif;
  align-items: left;
}

.product-details-hero-id {
  padding-left: 15px;
  height: 32px;
  margin-bottom: 37px;
  // font-weight: bold;
  font-size: 24px;
  color: #7d7f8a;
  font-family: 'Calibri', 'Trebuchet MS', sans-serif;
  align-items: left;
}

.selectedImage {
  border: 1px solid $color-primary-bright !important;
}

.product-details-hero-title-mobile {
  font-weight: 500;
  font-size: 1.3em;
  font-family: $font-archivo;
  margin-top: 20px;

  h6 {
    color: $color-secondary;
    padding-top: 15px;
  }
}

.product-details-hero-secondary-text {
  font-size: 1.7em;

  @media (max-width: $screen-xl-min) {
    font-size: 1.5em;
    position: relative;
    top: 3px;
  }

  @media (max-width: $screen-xl-min) {
    line-height: 32px;
  }

  color: $color-text-gray-dark;
  margin-bottom: 0;
}

.product-details-info {
  color: $color-text-gray-dark;

  label {
    font-weight: bold;
    font-size: 18px;
    width: 45%;
  }

  li {
    font-size: 18px;
  }
}

.product-details-info-column {
  margin-bottom: 10px;
}

.product-details-image-col {
  text-align: center;
  border: 2px solid #dddee2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-details-image-card {
  height: 680px;
  width: 71%;

  @media (max-width: 1600px) {
    width: 80%;
    height: 550px;
  }

  @media (max-width: $screen-xl-min) {
    position: static;
    margin-top: 35px;
    height: unset;
    width: 98%;
  }

  box-shadow: 1px 2px 20px 2px #cdcdcd;
  background-color: #fff;

  .carousel-indicators li {
    background-color: gray !important;
  }
}

a {
  text-decoration: none !important;
}

.product-options {
  display: flex;
  justify-content: space-around;
  background-color: #f1f3f7;
  color: #20272b !important;
  font-family: $font-campton;
  font-size: 1rem;
}

.pretty .state label:before {
  height: 20px !important;
  width: 20px !important;
}

.pretty .state label:after {
  margin: 1px;
}

.product-options .pretty .state label:before,
.product-options .pretty .state label:after {
  top: 26px !important;
}

.scroll-350 {
  max-height: 350px;
  overflow-y: scroll;
  width: fit-content;
}
.a-button-inner .col {
  display: flex;
  justify-content: center;
}

.product-options-item {
  padding-top: 26px;
  font-weight: 600;
}

.product-options-id {
  padding-top: 26px;
  display: block;
  width: 41px;
}
.product-options-dropdown {
  padding-top: 14px;
  // width: 150px;
  .Dropdown-control {
    padding-left: 13px;
  }
}
.product-options-availability {
  padding-top: 26px;
  display: block;
}

.product-options-price {
  padding-top: 26px;
  display: block;
}

.product-details__info-add {
  display: flex;
  justify-content: center;
}

.product-details-price-wrapper,
.product-details-quantity-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: $screen-xl-min) {
    margin-bottom: 20px;
  }

  #qty {
    padding-left: 8px;
    color: #7d7f8a;
    font-size: 16px;
    font-family: 'Campton', sans-serif;
  }

  .fancy-number-input {
    #plus {
      position: absolute;
      height: 28px;
      width: 16px;
      font-size: 20px;
      background-color: white;
      margin-top: 12px;
      margin-left: -18px;
      z-index: 3;
      padding-top: 8px;
      pointer-events: none;
    }

    #minus {
      position: absolute;
      height: 28px;
      width: 16px;
      font-size: 20px;
      background-color: white;
      margin-top: 12px;
      margin-left: 2px !important;
      padding-left: 8px;
      padding-top: 6px;
      z-index: 3;
      pointer-events: none;
    }

    .input-number {
      border: none;
      text-align: center;
    }

    .react-numeric-input {
      margin-left: 0px !important;
      width: 220px;
      height: 68px;

      @media (max-width: $screen-md-min) {
        input {
          position: absolute;
          left: 0;
          line-height: 32px !important;
          font-size: 15px !important;
          padding-left: unset !important;
          padding-right: unset !important;
          color: #5a5a5a;
          width: 116px;
        }

        b {
          right: 0px !important;
          bottom: 0px !important;
          width: 4.3ex !important;

          &:nth-of-type(2) {
            left: 0px !important;
            top: 0px !important;
          }
        }
      }
    }
  }
}

.product-details-price {
  font-size: 40px;
  color: $color-text-black;
  height: 53px;
}

.product-details-price-label {
  color: $color-text-gray-dark;
  font-size: 18px;
  font-weight: bold;
}

.product-details-packaging {
  height: 21px;
  margin-top: 28px;
  margin-bottom: 49px;
  font-family: $font-campton;
  font-size: 16px;
}

.padding {
  display: block;
  height: 297px;
  @media (max-width: $screen-xl-min) {
    height: auto;
  }
}

.add-items-to-list {
  display: block !important;
  height: 55px !important;
  width: 58px !important;
  font-size: 26px !important;
  background-color: $color-primary-danger-light !important;
  color: $color-primary-bright !important;
  border-radius: 5px !important;
  border: 1px solid $color-primary-danger-light !important;
  margin-top: 20px !important;

  &:hover {
    background-color: #fff !important;
    border: 1px solid $color-primary-danger !important;
  }
}

.product-details-support {
  display: block !important;
  height: 55px !important;
  width: 58px !important;
  font-size: 26px !important;
  background-color: $color-primary-danger-light !important;
  color: $color-primary-bright !important;
  border-radius: 5px !important;
  border: 1px solid $color-primary-danger-light !important;
  margin-top: 20px !important;

  &:hover {
    background-color: #fff !important;
    border: 1px solid $color-primary-danger !important;
  }
}
.product-details-modals > .modal-content {
  display: block !important;
  height: 327px !important;
  width: 426px !important;
}

.modal-header {
  border-bottom: hidden !important;
  flex-direction: column-reverse !important;
  padding-bottom: 10px !important;
}

.modal-title {
  padding-top: 20px;
  font-weight: 500;
  align-self: center;
  font-size: 20px !important;
  width: 100%;
  text-align: center;
}

.modal-title #modal-share-title {
  width: 100%;
  text-align: left;
  padding-left: 30px;
}

#modal-list-title {
  width: 100%;
  text-align: left;
  padding-left: 14px;
}

.close {
  align-self: flex-start;
}

.modal-body {
  a.fs15px {
    display: block;
    background-color: white !important;
    color: $color-primary-danger;
    padding: 30px;
    padding-top: 10px;
  }
}

.modal-body {
  .pretty .state label:before,
  .pretty .state label:after {
    top: -2px !important;
  }
}

.modal-span {
  display: block;
  text-align: center;
  font-family: 'Inv Maison Neue', 'Maison Neue', -apple-system, BlinkMacSystemFont, 'Open Sans',
    open-sans, sans-serif;
  font-size: 18px;
  color: #384349;

  label {
    color: #7d7f8a;
    padding-right: 16px !important;
  }
}

#phone {
  padding-right: 0 !important;
  padding-left: 16px;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.modal-footer {
  width: 100%;
  border-top: hidden !important;
  justify-content: center !important;
  padding-bottom: 44px !important;
  z-index: 2;
  padding-top: 30px !important;
}

.modal-footer .input-group {
  width: 355px;
}

.modal-footer .btn-lg {
  padding-left: 1rem !important;
}

#share-modal,
.modal-body {
  padding: 0 !important;
  margin-bottom: 20px;

  padding-bottom: 18px !important;

  .footer-social-media {
    display: block;
    height: 40px;
    padding-left: 30px;
    margin-top: 0;
    box-shadow: none;
    padding-top: 10px;
  }
}

.modal-body .scroll-200 {
  height: 139px;
}

.list-modal,
.share-modal,
.help-modal {
  margin-left: 10px;
  @media (max-width: $screen-xl-min) {
    display: inline-block !important;
    margin-left: 47px;
  }
}

.product-details-share {
  margin-right: 145px !important;
  display: block !important;
  height: 55px !important;
  width: 58px !important;
  font-size: 26px !important;
  background-color: $color-primary-danger-light !important;
  color: $color-primary-bright !important;
  border-radius: 5px !important;
  border: 1px solid $color-primary-danger-light !important;
  margin-top: 20px !important;

  &:hover {
    background-color: #fff !important;
    border: 1px solid $color-primary-danger !important;
  }
}
.Dropdown-root {
  width: 200px;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.Dropdown-placeholder {
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Dropdown-control {
  height: 45px;
  width: 100% !important;
}
.Dropdown-option {
  width: 100% !important;
  height: 45px;
  color: $color-text-black;
  background-color: #fff !important;
  font-size: 16px;
  width: 50px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f9eeeb !important;
  }
}

.product-details-dropdown {
  height: 45px;
  padding-right: 50px;
}

.product-details-quantity-label {
  color: $color-text-gray-dark;
  font-size: 18px;
  //font-weight: bold;
  margin-right: 12px;
}

.cushion {
  height: 10em;
}

.product-details-uom {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 218px;
}

.small-picture {
  height: 40px;
  width: 40px;
  margin-bottom: 5px;
  margin-right: 4px;
  border: 1px solid #a2a6ac;
}

// .large-image {
// min-height:500px;
//min-width: 500px;
// }

.add-to-cart-wrap {
  margin: 18px;

  div {
    margin: 0 auto;
  }

  .mdi {
    margin-right: 10px;
  }
}

.limited-quantity {
  color: $color-price;
}

.product-details-indicator-wrap {
  margin-bottom: 15px;
}

.product-details-special-order {
  color: #ca610b;
  font-weight: bold;
  font-size: 1.3em;
  font-family: $font-archivo;
}

.product-details-rx {
  font-size: 30px;
  position: relative;
  bottom: 4px;
}

.product-details-related-section {
  margin-top: 290px;
  padding-left: 15%;

  @media (max-width: 1600px) {
    margin-top: 200px;
  }

  @media (max-width: $screen-xl-min) {
    margin-top: 60px;
    padding-left: 15px;
  }
}

.product-details-related-label {
  @media (max-width: $screen-xl-min) {
    font-size: 1.5em;
    margin-top: 15px;
  }

  font-size: 1.65em;
  color: $color-text-black;
  margin-bottom: 10px;
}

.product-details-description {
  margin-top: 30px;
}

.product-details-description,
.product-details-description * {
  display: none;
  font-size: 18px !important;
  color: $color-text-black;

  li {
    font-size: 18px !important;
    color: $color-text-gray-dark;
  }

  p {
    text-indent: 0 !important;
  }
}

////////////////////
// Quantity Selector
////////////////////

.plus-minus {
  padding: 0 5px 0 8px;
  color: #7d7f8a;
  &:hover {
    color: black;
  }
}
.qty-btn {
  padding: 14px 5px;
  border: 1px solid #e4e6eb;
  border-radius: 5px;
}
.fancy-number-input {
  height: 65px;
  line-height: 2em !important;
  font-size: 18px !important;
  color: $color-text-gray-dark; //font-weight:bold;
  width: 75%;
  border: 1px solid #e4e6eb !important;

  .react-numeric-input {
    b {
      z-index: 1 !important;
      box-shadow: none !important;
      right: 0px !important;
      border: 1px solid #e4e6eb !important;
      border-radius: 0 !important;

      &:nth-of-type(1) {
        background-color: white !important;
        width: 1.5em !important;
        border: none !important;
        top: 1px !important;
        height: 63px;
        left: 195px;
      }

      &:nth-of-type(2) {
        background-color: white !important;
        width: 1.5em !important;
        top: 1px !important;
        left: 1px !important;
        height: 63px;
        border: none !important;
      }
    }
  }
}

.danger {
  color: $color-primary-danger;
}

.success {
  color: $color-text-black;
}
// .product-details-child-section {
//   padding-top: 10px;

//   .a-button-toggle.a-button-thumbnail .a-button-inner {
//     height: 74px;
//     overflow: hidden;
//     padding: 0;
//   }

//   .a-button-text {
//     color: #20272B;
//   }

//   .twisterSwatchWrapper_0 {
//     width: 946px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   .twisterSwatchWrapperNonValid_0 {
//     width: 100%;
//   }

//   .selected {

//     * {
//       font-weight: bold !important;
//     }
//   }

//   .twisterSwatchWrapper {
//     max-width: 180px;
//     padding-left: 15px;
//     padding-right: 15px;
//   }

//   .twisterTextDiv {
//     min-height: 19px;
//   }

//   .a-button-toggle.a-button-selected .a-button-text {
//     font-weight: 700;
//   }

//   .a-size-base {
//     font-size: 13px !important;
//     line-height: 19px !important;
//   }

//   .a-size-baseNonValid {
//     font-size: 13px !important;
//     //line-height: 19px !important;
//     margin-bottom: 5px !important;
//     margin-left: 10px !important;
//   }

//   .twisterSlotDiv {
//     min-height: 19px;
//   }

//   .a-button {
//     cursor: pointer;
//     display: block;
//     padding: 0;
//     text-align: left;
//     text-decoration: none !important;
//     margin: 4px 0 4px 6px;
//     margin-right: 10px;
//     margin-bottom: 10px;width: 946px;
//     background-color: #F1F3F7;
//   }

//   .a-size-mini {
//     font-size: 11px !important;
//     line-height: 1.465 !important;
//     display: block;

//     &.twisterSwatchPrice {
//       font-size: 14px !important;
//       margin-left: 12px;
//     }
//   }

//   .a-size-base {
//     font-size: 16px !important;
//     font-weight: 300;
//     line-height: 19px !important;
//     display: block;
//     color: $color-text-gray-dark;
//   }
// }

// .product-details-many-child-btn {
//   color: $color-text-black;
//   border-radius: 3px;
//   border: 1px solid rgb(194, 194, 194);
//   cursor: pointer;
//   vertical-align: middle;
//   margin-right: 10px;
//   margin-bottom: 12px;
//   background-color: white;
//   padding: 3px 12px;

//   &:disabled {
//     filter: opacity(0.5);
//     cursor: default;
//   }

//   &:hover:not(:disabled):not(.selected) {
//     box-shadow: 0 0 3px 2px rgba(192, 192, 192, 0.26);
//   }

//   &.selected {
//     border: 1px solid rgb(202, 117, 77) !important;
//     box-shadow: 0 0 3px 1px rgba(202, 117, 77, 0.349);
//     font-weight: bold;
//     color: $color-text-gray-dark;
//   }
// }

// .product-details-filter-button-group {
//   margin-bottom: 8px;

//   h5 {
//     color: #9c4c10;
//   }
// }

.product-details-alt-section {
  margin-top: 52px;
  padding-left: 8.33333%;
  padding-bottom: 35px;

  @media (max-width: $screen-lg-min) {
    padding-left: inherit;
  }
}

.product-details-alt-section-hr {
  @media (min-width: $screen-lg-min) {
    margin-right: 8.33333% !important;
  }
}

.product-details-alt-title {
  color: $color-primary;
  font-size: 1.8em;
  padding: 10px 0 40px 0;
  font-family: $font-archivo;

  @media (max-width: $screen-lg-min) {
    padding-left: 5% !important;
  }

  @media (max-width: $screen-xs-max) {
    font-size: 1.4em;
    padding-left: 7% !important;
  }
}

.alt-product-card-wrapper {
  &:not(:nth-child(1)) {
    margin-left: 30px;
  }

  &:hover a,
  u {
    text-decoration: none;
  }

  @media (min-width: 1800px) {
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }

  @media (max-width: $screen-lg-min) {
    &:not(:nth-child(1)) {
      margin-left: inherit;
    }

    margin-bottom: 45px;
  }

  @media (max-width: $screen-xs-max) {
    margin-bottom: 35px;
  }
}

.alt-product-card {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.212);

  &:hover {
    box-shadow: 0 2px 23px rgba(0, 0, 0, 0.243);
  }

  transition: box-shadow 0.15s;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0 10px 0;

  &.price-padding {
    padding-bottom: 60px;
  }

  @media (max-width: $screen-xl-min) {
    padding: 30px 0 8px 0;
  }

  @media (max-width: $screen-xs-max) {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.182);
    flex-direction: row;
  }
}

.alt-product-details {
  color: lighten($color-text-gray-dark, 5%);
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 1200px) {
    font-size: 0.8em;
  }

  @media (max-width: $screen-lg-min) {
    font-size: 1.2em;
  }

  @media (max-width: $screen-xs-max) {
    font-size: 0.9em;
  }

  & > .col {
    padding-left: 0 !important;
  }
}

.alt-product-title {
  padding-left: 10%;
  padding-right: 10%;
  font-weight: bold;
  color: #7a142c;
  padding-bottom: 16px;
  display: block;
  text-transform: uppercase;
  margin-top: 12px;
  font-size: 0.96em;
  line-height: 21px;

  @media (max-width: 1200px) {
    font-size: 0.9em;
  }

  @media (max-width: $screen-lg-min) {
    font-size: 1.25em;
    line-height: 28px;
  }

  @media (max-width: $screen-xs-max) {
    font-size: 0.8em;
    line-height: inherit;
    padding-left: 7%;
    padding-right: 7%;
    margin-top: 0;
  }
}

.product-details-alt-row {
  @media (max-width: $screen-lg-min) {
    justify-content: center;
  }
}

.alt-product-img-wrap {
  position: relative;
  text-align: center;
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: $screen-xs-max) {
    justify-content: center;
    height: 140px;
    padding-left: 15px;

    img {
      position: relative;
      bottom: 17px;
    }
  }
}

.alt-product-price {
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  color: $color-price;
  font-weight: bold;
  border-top: 1px solid #ececec;
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: $screen-xs-max) and (max-width: $screen-lg-min) {
    font-size: 20px;
  }
}

.alt-out-of-stock {
  @media (max-width: $screen-xs-max) {
    text-align: left;
  }
}
.smediaWrapper {
  padding-left: 30px;
  .smediaIcon {
    color: #b92a47;
    font-size: 20px;
    margin-right: 10px;
    &:hover {
      color: #7d7f8a;
    }
  }
}

.tab-container-desktop {
  background-color: #f1f3f7;
  padding: 20px 0px 20px 0px;
  margin-top: 75px;
  .nav-pills .nav-link.active {
    background-color: #212529 !important;
  }
}

.tab-container-mobile {
  background-color: #f1f3f7;
  padding: 20px 0px 20px 0px;
  margin-top: 75px;
  .nav-pills .nav-link.active {
    background-color: #212529 !important;
    @media (max-width: 440px) {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

.tabLink {
  color: #212529;
  font-size: 28px;
  font-family: $font-campton;
  margin-right: 100px;
  text-align: center;

  @media (max-width: 1320px) {
    margin-right: 30px;
  }
  @media (max-width: 1065px) {
    margin-right: 25px;
    font-size: 24px;
  }
  @media (max-width: 812px) {
    margin-right: 10px;
    font-size: 18px;
  }
  @media (max-width: 610px) {
    margin-right: 0px;
    font-size: 12px;
  }

  &:hover {
    color: white;
    background-color: #212529 !important;
  }
}
.nav-item {
  .nav-link {
    display: inline;
  }
}

.header {
  font-size: 40px;
  font-family: $font-campton;
  box-shadow: none;
  font-weight: 600;
}
.alt-prod-wrapper {
  overflow: scroll;
}
.crd {
  max-height: 366px;
  min-height: 366px;
  overflow: hidden;
  min-width: 266px;
  max-width: 266px;
  text-overflow: ellipsis;
  line-height: 1.3em;
  font-family: $font-campton;
  box-shadow: none;
  border: 1px solid #dddee2;
  margin-bottom: 20px;
  margin-right: 50px;
  font-size: 16px;
  a {
    color: #384349;
    &:hover {
      color: inherit;
    }
  }
  @media (max-width: 1150px) {
    max-height: 225px;
    min-height: 225px;
    font-size: 13px;
  }
  &:hover {
    box-shadow: 0px 0px 27px #acacac52;
    border: none;
    border-bottom: 3px solid #b92a47;
    cursor: pointer;
  }
}

.desktop-downloads {
  display: none;

  @media (min-width: 576px) {
    display: block;
    overflow: scroll;
  }
}

.mobile-downloads {
  display: none;

  @media (max-width: $screen-xs-max) {
    display: block;
  }
}

.download-card {
  height: 200px;
  background-color: $color-primary-danger-light;
  font-family: $font-campton;

  @media (max-width: $screen-md-max) {
    height: 150px;
  }

  @media (max-width: $screen-xs-max) {
    text-align: center;
  }

  .download-name {
    font-size: 32px;
    font-weight: 600;
    color: $color-text-black;
    padding: 20px 0 0 15px;

    @media (max-width: 1800px) {
      font-size: 25px;
    }
    @media (max-width: 1400px) {
      font-size: 20px;
    }
    @media (max-width: $screen-xs-max) {
      padding-left: 0px;
    }
  }

  .download-link {
    color: $color-primary-danger;
    font-size: 20px;
    font-weight: 600;
    background-color: $color-primary-danger-light;
    border: 'none';
    padding-left: 15px;

    @media (max-width: 1800px) {
      font-size: 17px;
    }
    @media (max-width: 1300px) {
      font-size: 15px;
    }
    @media (max-width: $screen-xs-max) {
      padding-left: 0px;
    }
  }
}

#rp {
  .col-lg-2 {
    @media (max-width: 1425px) {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
    @media (max-width: 1300px) {
      padding-right: 5px !important;
      padding-left: 5px !important;
    }
  }
}
.card-img,
.card-img-top {
  max-height: 187px;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  min-height: 187px;
  object-fit: cover;
  border-radius: 0 !important;
  @media (max-width: 1150px) {
    max-height: 125px;
    min-height: 125px;
  }
}
.altImg {
  position: relative;
  height: 200px;
  width: 150px;
  border: 1px solid #dddee2;

  @media (max-width: 1250px) {
    width: 200px;
  }

  &:hover {
    box-shadow: 0px 0px 27px #acacac52;
    border: none;
    border-bottom: 3px solid #b92a47;
    cursor: pointer;
  }
  img {
    position: absolute;
    top: 25%;
    left: 15%;
    height: 100px;
    width: 100px;

    @media (max-width: 1100px) {
      left: 25%;
    }
  }
}
.mnfctr {
  color: #7d7f8a;
  font-size: 18px;
  @media (max-width: 1350px) {
    font-size: 16px;
  }
  @media (max-width: 1150px) {
    font-size: 15px;
  }
  @media (max-width: 900px) {
    font-size: 14px;
  }
}
.no-alt-prods {
  font-size: 20px;
  font-family: $font-campton;
}
.description {
  color: #20272b;
  font-size: 22px;
  @media (max-width: 1350px) {
    font-size: 18px;
  }
  @media (max-width: 1150px) {
    font-size: 16px;
  }
  @media (max-width: 900px) {
    font-size: 14px;
  }
}
.price {
  color: #b92a47;
  font-size: 33px;
  @media (max-width: 1350px) {
    font-size: 26px;
  }
  @media (max-width: 1150px) {
    font-size: 20px;
  }
  @media (max-width: 900px) {
    font-size: 16px;
  }
}

.title {
  box-shadow: none;
  font-size: 19px;
  color: black;
}
.mdi-check-circle-outline {
  color: #b92a47;
  font-size: 22px;
}
.specs-title {
  color: #7d7f8a;
  background-color: #f1f3f7;
  padding: 15px;
  margin-bottom: 5px;
}
.specs-details {
  color: #20272b;
  background-color: #f1f3f7;
  padding: 15px;
  margin-bottom: 5px;
  margin-left: 5px;
}
.key-features {
  font-size: 20px;
  color: #384349;
  margin-bottom: 15px;
  @media (min-width: 1200px) {
    .mdi {
      max-width: 6% !important;
    }
  }
}
.related-product-crd {
  display: flex;
  align-items: center;
  max-height: 366px;
  min-height: 366px;
  overflow: hidden;
  min-width: 266px;
  max-width: 266px;
  text-overflow: ellipsis;
  line-height: 1.3em;
  font-family: $font-campton;
  box-shadow: none;
  border: 1px solid #dddee2;
  margin-bottom: 20px;
  margin-right: 50px;
  font-size: 16px;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #384349;
    &:hover {
      color: inherit;
    }
    img {
      padding: 10px;
    }
  }
  @media (max-width: 1150px) {
    max-height: 225px;
    min-height: 225px;
    font-size: 13px;
  }
  &:hover {
    box-shadow: 0px 0px 27px #acacac52;
    border: none;
    border-bottom: 3px solid #b92a47;
    cursor: pointer;
  }
}

.product-details-h1-display {
  font-size: 30px;
  font-weight: 700;
}

// @reach/dialog @reach/listbox and tiny-slider styling

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  border: 0;
  width: 1px;
  height: 1px;
  user-select: none;
}

.product-details__downloads-controls,
.product-details__related-products-controls,
.product-details__alternative-products-controls {
  display: none;
}

.tns-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 1rem;

  button {
    display: block;
    padding: 0;
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: 2px solid #b92a47;
    border-radius: 50%;
    transition: background-color 150ms ease-in-out;

    &.tns-nav-active {
      background-color: #b92a47;
    }

    &:hover,
    &:focus {
      background-color: #b92a47;
    }
  }
}

@media only screen and (min-width: 64em) {
  .product-details {
    &__downloads,
    &__related-products,
    &__alternative-products {
      position: relative;
    }

    &__downloads-controls,
    &__related-products-controls,
    &__alternative-products-controls {
      display: block;
    }

    &__downloads-control,
    &__related-products-control,
    &__alternative-products-control {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      width: 35px;
      height: 35px;
      background-color: #b92a47;
      border: none;
      border-radius: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: background-color 150ms ease-in-out;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 12px;
        height: 12px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: #ffffff;
        transition: border-color 150ms ease-in-out;
      }

      &--prev {
        left: -50px;

        &::before {
          left: calc(50% + 2px);
          transform: translate(-50%, -50%) rotate(135deg);
        }
      }

      &--next {
        right: -50px;

        &::before {
          left: calc(50% - 2px);
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      &:disabled {
        background-color: #acacac;
        cursor: auto;
      }

      &:hover,
      &:focus {
        &:not(:disabled) {
          background-color: transparent;
          border: 2px solid #b92a47;

          &::before {
            border-color: #b92a47;
          }
        }
      }
    }
  }

  .tns-controls {
    display: block;
    position: absolute;
    top: 50%;
  }
}

@media only screen and (min-width: 90em) {
  .product-details {
    &__downloads-control,
    &__related-products-control,
    &__alternative-products-control {
      width: 45px;
      height: 45px;

      &--prev {
        left: -55px;
      }

      &--next {
        right: -55px;
      }
    }
  }
}

@media only screen and (min-width: 95em) {
  .product-details {
    &__downloads-control,
    &__related-products-control,
    &__alternative-products-control {
      &--prev {
        left: -75px;
      }

      &--next {
        right: -75px;
      }
    }
  }
}

.product-details__qty-listbox[data-reach-listbox-input] {
  display: flex;

  [data-reach-listbox-button] {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 1.25rem;
    width: 100%;
    min-height: 46px !important;
    background-color: #ffffff;
    border: 1px solid #dddee2;
    border-radius: 4px;
    transition: border-color 150ms ease-in-out;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 0.25rem);
      right: 1.25rem;
      width: 0.75rem;
      height: 0.75rem;
      border-width: 0.125rem 0.125rem 0 0;
      border-style: solid;
      border-color: #b92a47;
      transform: translateY(-50%) rotate(135deg);
      transition: transform 150ms ease-in-out;
    }

    &[aria-expanded='true'] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::before {
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    &:hover,
    &:focus {
      outline: none;
      border-color: #b92a47;
    }
  }
}

[data-reach-listbox-popover] {
  padding: 0 !important;
  background-color: #ffffff;
  border: 1px solid #dddee2 !important;
  z-index: 99999;
}

[data-reach-listbox-list] {
  margin: 0;
}

.product-details__qty-listbox[data-reach-listbox-option] {
  padding: 1rem 1.25rem !important;
  background-color: transparent;
  transition: background-color 150ms ease-in-out;
  cursor: pointer;

  &[data-current-selected] {
    font-weight: 400 !important;
  }

  &[data-current-nav] {
    background-color: #f9eeeb !important;
    color: #000000 !important;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dddee2;
  }
}
