@import '../../assets/CSS/globals';
@import '../../assets/CSS/global_styles';
@import '~bootstrap/scss/bootstrap';
@import '../../components/Content/content';

@font-face {
  font-family: 'Campton-bold';
  src: url('../fonts/webfontkit/Campton-Bold.woff2') format('woff2'),
    url('../fonts/webfontkit/Campton-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Campton';
  src: url('../fonts/webfontkit/Campton-Medium.woff2') format('woff2'),
    url('../fonts/webfontkit/Campton-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Campton-light';
  src: url('../fonts/webfontkit/Campton-Light.woff2') format('woff2'),
    url('../fonts/webfontkit/Campton-Light.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Campton Book';
  src: url('../fonts/webfontkit/Campton-Book.woff2') format('woff2'),
    url('../fonts/webfontkit/Campton-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
