.dealmed-listbox {
  min-width: 60px;
  background-color: white;

  [data-reach-listbox-button] {
    padding: 0.625rem;
    width: 100%;
    min-height: 44px;
    border: 1px solid $grey-light-2;
    border-radius: 5px;
    transition: color 150ms ease-in-out;
    @media screen and (max-width: 767px) {
      line-height: 110%;
      font-size: 14px;
      min-height: 32px;
      padding: 0 8px;
    }

    &::before {
      content: none;
    }

    &:hover,
    &:focus {
      color: $red-dark;
    }
    & span svg {
      height: 12px;
      width: 12px;
      @media screen and (max-width: 767px) {
        height: 8px;
        width: 8px;
      }
    }
  }

  [data-reach-listbox-arrow] {
    color: currentColor;
  }
}
