@import '../../assets/CSS/globals';

/////////////////////
// CAROUSEL STYLES //
/////////////////////
.classic-carousel {
  .home-slide-caption-button {
    @media (max-width: $screen-lg-max) {
      padding: 10px 12px;
    }
  }
}

.home-mobile-hero {
  .home-slide-caption-button-container {
    display: block;

    &:hover a {
      text-decoration: none;
    }
  }

  .home-slide-caption-button {
    display: block;
    margin: 0 auto;
    font-size: 1em;
    white-space: nowrap;
    text-align: center;
  }

  .header-hero-caption-title {
    text-align: center;
    width: 95%;
    margin-top: -18px;
  }

  .header-hero-caption-title-text {
    top: 23%;
  }
}

///////////////////////
//  HOME HIGHLIGHTS  //
///////////////////////
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.home-highlights {
  opacity: 0;
  margin-top: 15px;
  padding-bottom: 72px;
  animation: fade-in 0.15s;
  //animation-delay: .1s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  /* Chrome 16+, Safari 4+ */
  -moz-animation-fill-mode: forwards;
  /* FF 5+ */
  -o-animation-fill-mode: forwards;
  /* Not implemented yet */
  -ms-animation-fill-mode: forwards;

  /* IE 10+ */
  @media (min-width: 1100px) {
    max-width: 1275px;
  }

  @media (max-width: 1100px) {
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    margin-top: 20px;
    padding-bottom: 50px;
    max-width: 928px;
  }

  .home-checks-container {
    @media (min-width: $screen-lg-max) and (max-width: 1450px) {
      padding-left: 40px;
    }

    @media (max-width: $screen-lg-max) {
      padding-left: 0;
    }
  }

  .home-check-image img {
    height: 46px;

    @media (max-width: $screen-lg-max) {
      height: 34px;
    }
  }

  .home-check-text {
    font-weight: bold;
    margin-left: 26px;
    font-size: 1.8em;
    color: #333333;

    @media (max-width: $screen-lg-max) {
      font-size: 1.3em;
      margin-left: 20px;
      bottom: 3px;
    }
  }

  .home-check-row {
    margin-bottom: 18px;
  }

  .home-guarantee-title {
    font-family: arial;
    font-size: 39.9px;
    line-height: 39.9px;
    color: $color-secondary;
    font-weight: bold;

    @media (max-width: $screen-lg-min) {
      padding: 0 !important;
    }

    @media (max-width: $screen-lg-max) {
      font-size: 1.9em;
      line-height: 31px;
      padding-right: 12px;
    }
  }

  .home-guarantee-container {
    @media (min-width: $screen-lg-max) and (max-width: 1450px) {
      padding-left: 30px;
    }

    @media (max-width: $screen-lg-max) {
      padding-left: 12px;
    }
  }

  .home-guarantee-text {
    line-height: 1.8em;
    margin-top: 20px;
    font-weight: bold;
    color: #4a4a4a;
    font-size: 1.1em;
    margin-bottom: 30px;

    @media (max-width: $screen-lg-max) {
      line-height: 1.6em;
      margin-bottom: 20px;
    }

    @media (max-width: $screen-lg-min) {
      padding: 0;
    }
  }

  .home-checkbox-footnote {
    color: gray;
    font-size: 1em;
    margin-top: 40px;
    font-style: italic;

    @media (max-width: $screen-lg-max) {
      font-size: 0.7em;
      padding-left: 18px;
      margin-top: 21px;
    }
  }

  .home-highlights-btns-container {
    @media (max-width: $screen-lg-max) {
      text-align: center;
    }

    @media (max-width: $screen-lg-min) {
      padding: 0 !important;
    }
  }
}

//////////////////////
//  MARKETS SERVED  //
//////////////////////
.home-markets-section-desktop .row:nth-child(1) {
  background-color: #e6e6e6;
  color: $color-primary;
  margin-bottom: 241px;
  padding-bottom: 25px;

  @media (max-width: 1025px) {
    margin-bottom: 50px;
  }
}

.home-markets-section-desktop {
  @media (max-width: $screen-lg-max) {
    display: none;
  }

  overflow: hidden; //to prevent animation of market items from creating horizontal scrollbar
  position: relative;
  padding-left: 0 !important;
  padding-right: 0 !important;

  @media (max-width: $screen-xs-max) {
    font-size: 1.3em;
  }
}

.home-markets-section-mobile {
  a {
    text-decoration: none;
  }

  @media (min-width: $screen-lg-max) {
    display: none;
  }

  background-color: #e6e6e6;
  margin-bottom: 55px;

  #home-markets-title {
    font-size: 1.5em;
    line-height: 1.25em;
  }

  .home-markets-card-icon img {
    height: 70px;
  }

  .home-markets-card-title {
    margin-bottom: 15px;
  }

  .home-markets-card-secondary-text {
    line-height: 1.1em;
    padding-left: 27px;
    padding-right: 27px;
  }

  .home-markets-card-icon {
    height: 45%;
    padding-top: 41px;
  }

  .carousel-indicators {
    bottom: -52px;
  }
}

#home-markets-title {
  color: $color-secondary;
  font-weight: bold;
  padding-top: 50px;
  padding-bottom: 220px;
  text-align: center;
  font-size: 1.6em;
  line-height: 1.6em;

  @media (min-width: $screen-xs-max) and (max-width: $screen-lg-max) {
    padding-left: 15%;
    padding-right: 15%;
    font-size: 1.7em;
  }

  @media (max-width: $screen-lg-max) {
    padding-top: 40px;
    padding-bottom: 29px;
    line-height: 1.4em;
    width: 100%;
  }
}

#home-markets-card-row {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 175px;

  a {
    text-decoration: none;
  }
}

.home-markets-card {
  background-color: white;
  height: 300px;
  text-align: center;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.26);
  cursor: pointer;

  @media (min-width: $screen-lg-max) {
    &:hover {
      background-color: $color-primary;
      transition: 0.26s;

      div {
        color: white;
        transition: 0.26s;
      }

      img {
        filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
        -moz-filter: brightness(0) invert(1);
        -o-filter: brightness(0) invert(1);
        -ms-filter: brightness(0) invert(1);
        transition: 0.26s;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    height: 315px;
    width: 75%;
    margin: 0 auto;
  }

  @media (min-width: $screen-xs-max) and (max-width: $screen-lg-max) {
    height: 250px;
    width: 54%;
    margin: 0 auto;
  }

  @media (min-width: 1026px) and (max-width: 1270px) {
    .home-markets-card-secondary-text {
      font-size: 1em;
      line-height: 1.4em;
    }
  }

  @media (min-width: 1270px) and (max-width: 1390px) {
    height: 320px;

    .home-markets-card-secondary-text {
      font-size: 1em;
    }

    .home-markets-card-title {
      font-size: 1.1em;
    }
  }

  @media (min-width: 1390px) and (max-width: 1575px) {
    height: 318px;

    .home-markets-card-secondary-text {
      font-size: 1.1em;
    }
  }
}

.home-markets-card-icon {
  height: 40%;
  padding-top: 39px;

  @media (min-width: $screen-xs-max) and (max-width: 1025px) {
    padding-top: 20px;
  }

  #steth {
    height: 76px;
    position: relative;
    bottom: 10px;
  }

  #iv {
    height: 58px;
  }

  #scalpel {
    height: 62px;
  }

  #ambulance {
    height: 62px;
  }

  #tubes {
    height: 60px;
  }
}

.home-markets-card-title {
  font-weight: bold;
  font-size: 1.2em;
  color: #383838;
  margin-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
}

.home-markets-card-secondary-text {
  color: #383838;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 1.2em;
}

.home-markets-mobile-carousel {
  height: 374px;

  @media (min-width: $screen-xs-max) and (max-width: $screen-lg-max) {
    height: 325px;
  }
}

.animate-hidden {
  opacity: 0;
}

@keyframes fade-in-scale {
  from {
    opacity: 0;
    transform: scale(0.75, 0.75);
  }

  to {
    opacity: 1;
  }
}

.home-markets-title-animate {
  animation: fade-in-scale 1.5s;
}

@keyframes market-item-left {
  from {
    opacity: 0;
    transform: translateX(203px);
  }

  to {
    opacity: 1;
  }
}

@keyframes market-item-right {
  from {
    opacity: 0;
    transform: translateX(-203px);
  }

  to {
    opacity: 1;
  }
}

.home-markets-item-animate {
  &:nth-child(1),
  &:nth-child(2) {
    animation: market-item-right 2s;
  }

  &:nth-child(3) {
    animation: fade-in-scale 2s;
  }

  &:nth-child(4),
  &:nth-child(5) {
    animation: market-item-left 2s;
  }
}

// AE STYLES

// Dealmed Home Hero

.dealmed-home-hero {
  margin-bottom: 2rem;
  background-color: #f1f3f7;

  .dealmed-container {
    padding: 0;
    max-width: 120rem;
  }
}

.dealmed-home-hero__image {
  display: block;
  max-width: 100%;
  object-fit: contain;
}

.dealmed-home-hero__content {
  padding-top: 10px;
  padding-bottom: 10px;

  .dealmed-container {
    padding: 0 16px;
    max-width: 90rem;
  }
}

.dealmed-home-hero__heading {
  margin: 0 0 1rem;
  color: #b92a47;
  font-size: 2rem;
  font-weight: 600;
}

.dealmed-home-hero__heading-span {
  display: block;
}

.dealmed-home-hero__text {
  margin: 0 0 1.5rem;
  color: #20272b;
  font-size: 0.875rem;
}

.dealmed-home-hero__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 3.375rem;
  padding: 1em 0;
  width: 100%;
  color: #ffffff;
  background-color: #b92a47;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  transition: background-color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #20272b;
  }
}

.dealmed-home-hero__icon-wrapper {
  margin-right: 10px;
}

.dealmed-home-hero__icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  fill: currentColor;
}

// Dealmed Home Categories
.dealmed-home-categories {
  margin-bottom: 2rem;
}

.dealmed-home-categories__top {
  .dealmed-container {
    max-width: 90rem;
  }
}

.dealmed-home-categories__bottom {
  .dealmed-container {
    padding: 0;
    max-width: 120rem;
  }

  background-color: #f1f3f7;
}

.dealmed-home-categories__banner {
  .dealmed-container {
    padding: 0 16px;
    max-width: 90rem;
  }
}

.dealmed-home-categories__heading {
  color: #384349;
  text-align: center;
  font-size: 1.875rem;
}

.dealmed-home-categories__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.dealmed-home-categories__item {
  flex-basis: 100%;
  width: 100%;
  border-bottom: 2px solid #f9eeeb;

  &--active {
    border-bottom-width: 4px;
    border-bottom-color: #b92a47;
  }
}

.dealmed-home-categories__button {
  display: flex;
  position: relative;
  padding: 15px 0;
  width: 100%;
  min-height: 50px;
  color: #384349;
  background-color: transparent;
  border: none;
  text-align: left;
  font-size: 1.25rem;
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 30px;
    right: 0;
    width: 18px;
    height: 2px;
    background-color: #7d7f8a;
    transition: background-color 150ms ease-in-out;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 30px;
    right: 0;
    width: 18px;
    height: 2px;
    background-color: #7d7f8a;
    transform: rotate(90deg);
    transition: background-color 150ms ease-in-out, transform 150ms ease-in-out;
  }

  &:hover,
  &:focus {
    color: #b92a47;

    &::before,
    &::after {
      background-color: #b92a47;
    }
  }

  &--active {
    color: #b92a47;

    &::before {
      background-color: #b92a47;
    }

    &::after {
      background-color: #b92a47;
      transform: rotate(0);
    }
  }
}

.dealmed-home-categories__icon {
  display: block;
  flex-shrink: 0;
  margin-right: 18px;
  width: 65px;
  height: 65px;
  object-fit: contain;
}

.dealmed-home-categories__title {
  margin: 0;
  padding-right: 20px;
}

.dealmed-home-categories__content {
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  background-color: #f1f3f7;
  transition: visibility 150ms ease-in-out, opacity 150ms ease-in-out, max-height 400ms;

  &--active {
    visibility: visible;
    opacity: 1;
    max-height: 600px;
  }
}

.dealmed-home-categories__wrapper {
  padding: 36px 20px;
}

.dealmed-home-categories__text {
  margin: 0 0 20px;
  color: #20272b;
  font-size: 1rem;
}

.dealmed-home-categories__link {
  display: inline-block;
  padding: 1em 0;
  width: 100%;
  color: #ffffff;
  background-color: #7d7f8a;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  transition: background-color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #b92a47;
  }
}

// Dealmed Home Run Profitably
.dealmed-home-run {
  margin-bottom: 2rem;

  .dealmed-container {
    padding: 0;
    max-width: 120rem;
  }
}

.dealmed-home-run__accordions {
  .dealmed-container {
    padding: 0 16px;
    max-width: 90rem;
  }
}

.dealmed-home-run__heading {
  color: #384349;
  text-align: center;
  font-size: 1.875rem;
}

.dealmed-home-run__list {
  margin: 0 0 1rem;
  padding: 0;
}

.dealmed-home-run__item {
  border-bottom: 1px solid #bebfc4;

  &--active {
    border-bottom: 4px solid #b92a47;
  }
}

.dealmed-home-run__button {
  display: block;
  position: relative;
  padding: 1em 0.5em;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: color 150ms ease-in-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 18px;
    height: 2px;
    background-color: #7d7f8a;
    transform: translateY(-50%);
    transition: background-color 150ms ease-in-out;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 18px;
    height: 2px;
    background-color: #7d7f8a;
    transform: translateY(-50%) rotate(90deg);
    transition: transform 150ms ease-in-out, background-color 150ms ease-in-out;
  }

  &:hover,
  &:focus {
    color: #b92a47;

    &::before,
    &::after {
      background-color: #b92a47;
    }
  }

  &--active {
    color: #b92a47;

    &::before {
      background-color: #b92a47;
    }

    &::after {
      background-color: #b92a47;
      transform: translateY(-50%) rotate(0);
    }
  }
}

.dealmed-home-run__title {
  margin: 0;
  font-size: 1.25rem;
  transition: color 150ms ease-in-out;
}

.dealmed-home-run__content {
  padding: 0 1em;
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  transition: visibility 150ms ease-in-out, opacity 150ms ease-in-out, max-height 400ms;

  &--active {
    visibility: visible;
    opacity: 1;
    max-height: 350px;
  }
}

.dealmed-home-run__text {
  color: #20272b;
  font-size: 1rem;
}

.dealmed-home-run__philosophy {
  display: inline-block;
  padding: 1em 0;
  width: 100%;
  color: #ffffff;
  background-color: #7d7f8a;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #b92a47;
  }
}

// Dealmed Value Solutions
.dealmed-home-solutions {
  .dealmed-container {
    max-width: 90rem;
  }
}

.dealmed-home-solutions__span {
  display: block;
  color: #b92a47;
  text-align: center;
  font-size: 1.125rem;
}

.dealmed-home-solutions__heading {
  color: #384349;
  text-align: center;
  font-size: 1.875rem;
}

.dealmed-home-solutions__item {
  border-bottom: 1px solid #bebfc4;

  &--active {
    border-bottom: 4px solid #b92a47;
  }
}

.dealmed-home-solutions__button {
  display: block;
  position: relative;
  padding: 1em 0.5em;
  width: 100%;
  color: #384349;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: color 150ms ease-in-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 18px;
    height: 2px;
    background-color: #7d7f8a;
    transform: translateY(-50%);
    transition: background-color 150ms ease-in-out;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    width: 18px;
    height: 2px;
    background-color: #7d7f8a;
    transform: translateY(-50%) rotate(90deg);
    transition: transform 150ms ease-in-out, background-color 150ms ease-in-out;
  }

  &:hover,
  &:focus {
    color: #b92a47;

    &::before,
    &::after {
      background-color: #b92a47;
    }
  }

  &--active {
    color: #b92a47;

    &::before {
      background-color: #b92a47;
    }

    &::after {
      background-color: #b92a47;
      transform: translateY(-50%) rotate(0);
    }
  }
}

.dealmed-home-solutions__title {
  margin: 0;
  max-width: 22ch;
  font-size: 1.25rem;
}

.dealmed-home-solutions__content {
  padding: 0 0.5em;
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  transition: visibility 150ms ease-in-out, opacity 150ms ease-in-out, max-height 400ms;

  &--active {
    visibility: visible;
    opacity: 1;
    max-height: 350px;
  }
}

.dealmed-home-solutions__text {
  color: #20272b;
  font-size: 1rem;
}

.dealmed-home-solutions__link {
  display: inline-block;
  margin-bottom: 40px;
  padding: 1em 0;
  width: 100%;
  color: #ffffff;
  background-color: #7d7f8a;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #b92a47;
  }
}

// Dealmed Home Serve
.dealmed-home-serve {
  margin-bottom: 2rem;

  .dealmed-container {
    max-width: 90rem;
  }
}

.dealmed-home-serve__span {
  display: block;
  color: #b92a47;
  text-align: center;
  font-size: 1.125rem;
}

.dealmed-home-serve__heading {
  color: #384349;
  text-align: center;
  font-size: 1.875rem;
}

.dealmed-home-serve__item {
  padding: 1em 0;
  border-bottom: 1px solid #bebfc4;
}

.dealmed-home-serve__button {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
  color: #384349;
  background-color: transparent;
  border: none;
  border-radius: 0;
  text-align: left;
  font-size: 1.25rem;
  transition: color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: #b92a47;

    .dealmed-home-serve__icon-wrapper {
      background-color: #b92a47;
    }

    .dealmed-home-serve__icon {
      fill: #ffffff;
    }
  }
}

.dealmed-home-serve__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 54px;
  min-height: 54px;
  background-color: #f9eeeb;
  transition: background-color 150ms ease-in-out;
}

.dealmed-home-serve__icon {
  width: 24px;
  height: 24px;
  fill: #b92a47;
  transition: fill 150ms ease-in-out;
}

// Dealmed Home Account
.dealmed-home-account {
  background-color: #f9eeeb;

  .dealmed-container {
    max-width: 120rem;
  }
}

.dealmed-home-account__content {
  .dealmed-container {
    padding: 60px 20px;
    max-width: 90rem;
  }
}

.dealmed-home-account__heading {
  margin: 0 0 30px;
  color: #384349;
  font-size: 1.875rem;
}

.dealmed-home-account__text {
  color: #20272b;
  font-size: 1rem;
}

.dealmed-home-account__link {
  display: inline-block;
  padding: 1em 0;
  width: 100%;
  color: #ffffff;
  background-color: #7d7f8a;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  transition: background-color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #b92a47;
  }
}

// Dealmed Home About
.dealmed-home-about {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 910px;
  z-index: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: hsla(0, 0%, 0%, 0.65);
    z-index: -1;
  }

  .dealmed-container {
    padding: 50px 20px 0;
    max-width: 90rem;
  }
}

.dealmed-home-about__heading {
  margin: 0 0 24px;
  color: #b92a47;
  font-size: 1.25rem;
}

.dealmed-home-about__subheading {
  color: #ffffff;
  font-size: 1.875rem;
}

.dealmed-home-about__text {
  color: #ffffff;
  font-size: 1.375rem;
}

.dealmed-home-about__list {
  margin: 0;
  padding: 0 0 20px;
  list-style-type: none;
}

.dealmed-home-about__item {
  position: relative;
  padding: 2rem;
  color: #384349;
  background-color: #ffffff;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    height: 1px;
    background-color: #b92a47;
  }

  &:last-child {
    &::before {
      content: none;
    }
  }
}

.dealmed-home-about__item-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.dealmed-home-about__item-icon-wrapper {
  display: flex;
  margin-right: 0.625rem;
}

.dealmed-home-about__item-icon {
  width: 2rem;
  height: 2rem;
  fill: #b92a47;

  &.dealmed-icon {
    width: 2rem;
    height: 2rem;
    fill: #b92a47;
  }
}

.dealmed-home-about__item-title {
  margin: 0;
  font-size: 1.25rem;
}

.dealmed-home-about__item-text {
  margin: 0;
  font-size: 1.25rem;
  font-style: italic;
}

// Dealmed Home Business
.dealmed-home-business {
  background-color: hsla(348, 63%, 45%, 0.9);

  .dealmed-container {
    padding: 45px 20px;
    max-width: 90rem;
    background-repeat: no-repeat;
    background-position: 75px center;
    background-size: cover;
  }
}

.dealmed-home-business__heading {
  margin: 0 0 25px;
  color: #ffffff;
  max-width: 14ch;
  font-size: 1.875rem;
}

.dealmed-home-business__link {
  display: inline-block;
  padding: 1em 0;
  width: 100%;
  color: #ffffff;
  background-color: #7d7f8a;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  transition: background-color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #20272b;
  }
}

@media only screen and (min-width: 23em) {
  .dealmed-home-solutions__title {
    max-width: 26ch;
  }
}

@media only screen and (min-width: 30em) {
  .dealmed-home-solutions__title {
    max-width: none;
  }
}

@media only screen and (min-width: 36em) {
  .dealmed-home-hero__link,
  .dealmed-home-categories__link,
  .dealmed-home-run__philosophy,
  .dealmed-home-solutions__link,
  .dealmed-home-account__link,
  .dealmed-home-business__link {
    padding: 1em 2em;
    width: auto;
  }

  .dealmed-home-about {
    min-height: 715px;
  }
}

@media only screen and (min-width: 48em) {
  .dealmed-home-hero {
    margin-bottom: 6rem;

    .dealmed-container {
      position: relative;
      padding: 30px 0;
    }
  }

  .dealmed-home-hero__image {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 100%;
    width: 445px;
  }

  .dealmed-home-hero__content {
    .dealmed-container {
      padding: 0 16px;
    }
  }

  .dealmed-home-hero__heading {
    max-width: 22ch;
  }

  .dealmed-home-hero__text {
    max-width: 34ch;
    font-size: 1.125rem;
  }

  .dealmed-home-hero__link {
    margin-bottom: 0;
  }

  .dealmed-home-categories {
    margin-bottom: 4rem;
  }

  .dealmed-home-categories__heading {
    margin: 0 auto 2rem;
    max-width: 30ch;
    line-height: 4rem;
    font-size: 2.5rem;
  }

  .dealmed-home-categories__list {
    margin-bottom: 10px;
  }

  .dealmed-home-categories__item {
    flex-basis: 50%;
  }

  .dealmed-home-categories__button {
    &::before,
    &::after {
      display: none;
    }
  }

  .dealmed-home-categories__banner {
    padding: 25px 0;
    background-repeat: no-repeat;
    background-position: clamp(250px, 100%, 100%);
    background-size: contain;
  }

  .dealmed-home-categories__category {
    max-width: 22ch;
    color: #b92a47;
    line-height: 2.5rem;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .dealmed-home-categories__text {
    max-width: 32ch;
    color: #20272b;
    line-height: 1.75rem;
  }

  .dealmed-home-run__heading {
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }

  .dealmed-home-run__wrapper {
    display: flex;
    gap: 10px;
  }

  .dealmed-home-run__banner {
    flex: 1;
  }

  .dealmed-home-run__accordions {
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 475px;

    .dealmed-container {
      margin-left: 0;
      padding-left: 0;
      width: 100%;
      max-width: 45rem;
    }
  }

  .dealmed-home-run__banner-wrapper {
    position: relative;
    height: 100%;
  }

  .dealmed-home-run__image {
    display: block;
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 320px;
    object-fit: contain;
    object-position: center;
  }

  .dealmed-home-run__item {
    padding: 5px 0 5px 25px;
  }

  .dealmed-home-run__content {
    padding: 0 0.5em;
  }

  .dealmed-home-run__text {
    color: #7d7f8a;
  }

  .dealmed-home-solutions {
    margin-bottom: 4rem;
  }

  .dealmed-home-solutions__span {
    font-size: 1.375rem;
  }

  .dealmed-home-solutions__heading {
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }

  .dealmed-home-solutions__wrapper {
    display: flex;
    gap: 10px;
  }

  .dealmed-home-solutions__banner {
    display: flex;
    position: relative;
    flex: 1;
  }

  .dealmed-home-solutions__banner-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 435px;
    object-fit: contain;
  }

  .dealmed-home-solutions__image {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .dealmed-home-solutions__accordions {
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 450px;
  }

  .dealmed-home-solutions__item {
    position: relative;
    padding: 5px 0 5px 25px;

    &::before {
      content: none;
      display: block;
      position: absolute;
      bottom: 4px;
      left: -1px;
      width: 20px;
      height: 4px;
      background-color: #b92a47;
      transform: rotate(-45deg);
    }

    &--active {
      &::before {
        content: '';
      }
    }
  }

  .dealmed-home-solutions__text {
    color: #7d7f8a;
  }

  .dealmed-home-serve {
    margin-bottom: 4rem;
  }

  .dealmed-home-serve__span {
    font-size: 1.375rem;
  }

  .dealmed-home-serve__heading {
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }

  .dealmed-home-serve__content {
    display: flex;
  }

  .dealmed-home-serve__banner,
  .dealmed-home-serve__list {
    flex: 1;
  }

  .dealmed-home-serve__banner-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .dealmed-home-serve__banner-download {
    display: inline-block;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      background-color: hsla(0, 0%, 0%, 0.5);
      transition: visibility 150ms ease-in-out, opacity 150ms ease-in-out;
    }

    &::after {
      content: 'Download PDF';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 0.75em 1.25em;
      color: #ffffff;
      visibility: hidden;
      opacity: 0;
      background-color: #b92a47;
      border-radius: 5px;
      text-transform: uppercase;
      transform: translate(-50%, -50%);
      transition: visibility 150ms ease-in-out, opacity 150ms ease-in-out;
    }

    &:hover,
    &:focus {
      &::before,
      &::after {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .dealmed-home-serve__banner-image {
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 535px;
    object-fit: contain;
  }

  .dealmed-home-serve__list {
    margin-left: auto;
    max-width: 535px;
  }

  .dealmed-home-serve__item {
    position: relative;

    &::before {
      content: none;
      display: block;
      position: absolute;
      bottom: 4px;
      left: -1px;
      width: 20px;
      height: 4px;
      background-color: #b92a47;
      transform: rotate(-45deg);
    }

    &--active {
      border-bottom-width: 4px;
      border-bottom-color: #b92a47;

      &::before {
        content: '';
      }
    }
  }

  .dealmed-home-serve__button {
    padding: 5px 0 5px 30px;
  }

  .dealmed-home-account {
    .dealmed-container {
      position: relative;
    }
  }

  .dealmed-home-account__image {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 490px;
    max-width: 100%;
    object-fit: contain;
  }

  .dealmed-home-account__heading {
    max-width: 18ch;
    line-height: 2.5rem;
    font-size: 2rem;
  }

  .dealmed-home-account__text {
    margin-bottom: 30px;
    max-width: 35ch;
    color: #7d7f8a;
    line-height: 1.75rem;
    font-size: 1rem;
  }

  .dealmed-home-about {
    min-height: 0;
    z-index: 1;
  }

  .dealmed-home-about__heading {
    font-size: 1.5rem;
  }

  .dealmed-home-about__subheading {
    font-size: 2.5rem;
  }

  .dealmed-home-about__text {
    margin-bottom: 60px;
    max-width: 40ch;
    line-height: 1.875rem;
    font-size: 1rem;
  }

  .dealmed-home-about__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    transform: translateY(50%);
  }

  .dealmed-home-about__item {
    flex-basis: 50%;
    width: 50%;
    padding: 1.5rem;

    &::before {
      top: 1rem;
      bottom: 1rem;
      left: unset;
      right: 0;
      width: 1px;
      height: unset;
    }

    &:nth-of-type(2n) {
      &::before {
        content: none;
      }
    }

    &:nth-of-type(n + 3) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 1rem;
        right: 1rem;
        height: 1px;
        background-color: #b92a47;
      }
    }
  }

  .dealmed-home-business {
    .dealmed-container {
      position: relative;
      padding-top: 250px;
    }
  }

  .dealmed-home-business__heading {
    margin-bottom: 65px;
    line-height: 3rem;
    font-size: 2.5rem;
  }

  .dealmed-home-business__image {
    display: block;
    position: absolute;
    bottom: 0%;
    right: 0;
    width: 230px;
    max-width: 100%;
    object-fit: contain;
    pointer-events: none;
  }
}

@media only screen and (min-width: 55em) {
  .dealmed-home-hero {
    .dealmed-container {
      padding: 60px 0;
    }
  }

  .dealmed-home-hero__image {
    width: 465px;
  }

  .dealmed-home-hero__content {
    .dealmed-container {
      padding: 0 16px;
    }
  }

  .dealmed-home-hero__heading {
    max-width: none;
  }

  .dealmed-home-hero__text {
    max-width: 45ch;
  }

  .dealmed-home-categories__heading {
    font-size: 2.75rem;
  }

  .dealmed-home-categories__banner {
    padding: 40px 0;
  }

  .dealmed-home-categories__category {
    margin-bottom: 24px;
    font-size: 1.875rem;
  }

  .dealmed-home-categories__text {
    margin-bottom: 24px;
    max-width: 40ch;
  }

  .dealmed-home-run__heading {
    font-size: 2.75rem;
  }

  .dealmed-home-solutions__heading {
    font-size: 2.75rem;
  }

  .dealmed-home-serve__heading {
    font-size: 2.75rem;
  }

  .dealmed-home-account__image {
    width: 555px;
  }

  .dealmed-home-account__heading {
    margin-bottom: 35px;
    line-height: 3.5rem;
    font-size: 2.5rem;
  }

  .dealmed-home-account__text {
    margin-bottom: 35px;
    font-size: 1.125rem;
  }

  .dealmed-home-about__subheading {
    font-size: 3rem;
  }

  .dealmed-home-business {
    .dealmed-container {
      padding: 275px 20px 105px;
    }
  }

  .dealmed-home-business__heading {
    margin-bottom: 85px;
    line-height: 5rem;
    font-size: 4rem;
  }

  .dealmed-home-business__image {
    width: 385px;
  }
}

@media only screen and (min-width: 62em) {
  .dealmed-home-hero__image {
    width: 500px;
  }

  .dealmed-home-hero__heading {
    font-size: 2.5rem;
  }

  .dealmed-home-hero__text {
    font-size: 1.25rem;
  }

  .dealmed-home-categories__heading {
    margin-bottom: 4rem;
    line-height: 5rem;
    font-size: 3rem;
  }

  .dealmed-home-categories__list {
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

  .dealmed-home-categories__item {
    position: relative;
    flex-basis: 100%;
    padding-left: 20px;
    border: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: calc(100% - 30px);
      background-color: #f9eeeb;
      transition: background-color 150ms ease-in-out;
    }

    &--active {
      &::before {
        background-color: #b92a47;
      }
    }
  }

  .dealmed-home-categories__button {
    position: relative;
    flex-direction: column;
    padding: 30px 0;
    font-size: 1.125rem;
  }

  .dealmed-home-categories__icon {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .dealmed-home-categories__title {
    max-width: 20ch;
  }

  .dealmed-home-categories__category {
    margin-bottom: 32px;
    line-height: 3rem;
    font-size: 2.125rem;
  }

  .dealmed-home-categories__text {
    margin-bottom: 32px;
    max-width: 50ch;
    line-height: 2rem;
  }

  .dealmed-home-run__heading {
    font-size: 3rem;
  }

  .dealmed-home-run__image {
    max-height: 415px;
  }

  .dealmed-home-run__accordions {
    min-height: 650px;
  }

  .dealmed-home-run__list {
    margin-bottom: 4rem;
  }

  .dealmed-home-run__item {
    padding: 10px 0 10px 25px;

    &--active {
      border-width: 10px;
    }
  }

  .dealmed-home-run__title {
    font-size: 1.5rem;
  }

  .dealmed-home-run__text {
    font-size: 1.125rem;
  }

  .dealmed-home-solutions__heading {
    margin-bottom: 4rem;
    font-size: 3rem;
  }

  .dealmed-home-solutions__banner-image {
    max-height: 600px;
  }

  .dealmed-home-solutions__accordions {
    min-height: 600px;
  }

  .dealmed-home-solutions__item {
    padding: 10px 0 10px 25px;

    &::before {
      left: -31px;
      width: 35px;
      height: 10px;
    }

    &::after {
      content: none;
      display: block;
      position: absolute;
      bottom: -10px;
      left: -30px;
      width: 30px;
      height: 10px;
      background-color: #b92a47;
    }

    &--active {
      border-bottom-width: 10px;

      &::after {
        content: '';
      }
    }
  }

  .dealmed-home-solutions__title {
    font-size: 1.5rem;
  }

  .dealmed-home-solutions__text {
    font-size: 1.125rem;
  }

  .dealmed-home-serve__heading {
    margin-bottom: 4rem;
    font-size: 3rem;
  }

  .dealmed-home-serve__banner-image {
    max-height: 735px;
  }

  .dealmed-home-serve__item {
    &::before {
      bottom: 4px;
      left: -1px;
      width: 35px;
      height: 10px;
    }

    &--active {
      border-bottom-width: 10px;
    }
  }

  .dealmed-home-serve__button {
    padding: 10px 0 10px 50px;
    font-size: 1.5rem;
  }

  .dealmed-home-account__image {
    width: 600px;
  }

  .dealmed-home-account__heading {
    line-height: 4rem;
    font-size: 3rem;
  }

  .dealmed-home-account__text {
    line-height: 2rem;
    font-size: 1.375rem;
  }

  .dealmed-home-about__heading {
    margin-bottom: 32px;
  }

  .dealmed-home-about__subheading {
    margin-bottom: 32px;
    font-size: 3.5rem;
  }

  .dealmed-home-about__text {
    margin-bottom: 100px;
  }

  .dealmed-home-about__item {
    flex: 1;
    width: 100%;
    padding: 2rem 1.125rem;

    &:nth-of-type(2n):not(:last-child) {
      &::before {
        content: '';
      }
    }

    &:nth-of-type(n + 3) {
      &::after {
        content: none;
      }
    }
  }

  .dealmed-home-about__item-header {
    margin-bottom: 2.5rem;
  }

  .dealmed-home-about__item-icon {
    width: 1.25rem;
    height: 1.25rem;

    &.dealmed-icon {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .dealmed-home-about__item-title,
  .dealmed-home-about__item-text {
    font-size: 1rem;
  }

  .dealmed-home-business {
    .dealmed-container {
      padding: 300px 20px 125px;
    }
  }

  .dealmed-home-business__image {
    width: 420px;
  }
}

@media only screen and (min-width: 70em) {
  .dealmed-home-hero {
    .dealmed-container {
      padding: 80px 0;
    }
  }

  .dealmed-home-hero__image {
    width: 550px;
  }

  .dealmed-home-hero__content {
    .dealmed-container {
      padding: 0 16px;
    }
  }

  .dealmed-home-categories__item {
    padding-left: 25px;
  }

  .dealmed-home-categories__banner {
    padding: 45px 0;
  }

  .dealmed-home-categories__heading {
    font-size: 3.5rem;
  }

  .dealmed-home-categories__category {
    line-height: 3.75rem;
    font-size: 2.5rem;
  }

  .dealmed-home-categories__text {
    line-height: 2.5rem;
    font-size: 1.125rem;
  }

  .dealmed-home-run__heading {
    font-size: 3.5rem;
  }

  .dealmed-home-solutions__heading {
    font-size: 3.5rem;
  }

  .dealmed-home-serve__heading {
    font-size: 3.5rem;
  }

  .dealmed-home-serve__button {
    font-size: 1.875rem;
  }

  .dealmed-home-account__image {
    width: 670px;
  }

  .dealmed-home-account__heading {
    line-height: 5rem;
    font-size: 3.5rem;
  }

  .dealmed-home-account__text {
    line-height: 2.5rem;
  }

  .dealmed-home-about {
    .dealmed-container {
      padding-top: 90px;
    }
  }

  .dealmed-home-about__subheading {
    font-size: 4rem;
  }

  .dealmed-home-about__text {
    margin-bottom: 150px;
    font-size: 1.375rem;
  }
}

@media only screen and (min-width: 75em) {
  .dealmed-home-hero {
    .dealmed-container {
      padding: 95px 0;
    }
  }

  .dealmed-home-hero__image {
    width: 610px;
  }

  .dealmed-home-hero__content {
    .dealmed-container {
      padding: 0 16px;
    }
  }

  .dealmed-home-hero__heading {
    font-size: 3rem;
  }

  .dealmed-home-hero__text {
    font-size: 1.375rem;
  }

  .dealmed-home-categories__heading {
    margin-bottom: 6rem;
    font-size: 3.75rem;
  }

  .dealmed-home-categories__item {
    padding-left: 30px;
  }

  .dealmed-home-categories__button {
    padding-bottom: 50px;
  }

  .dealmed-home-categories__banner {
    padding: 55px 0;
  }

  .dealmed-home-categories__category {
    font-size: 3rem;
  }

  .dealmed-home-categories__text {
    font-size: 1.25rem;
  }

  .dealmed-home-run__heading {
    font-size: 3.75rem;
  }

  .dealmed-home-run__image {
    max-height: 465px;
  }

  .dealmed-home-solutions__heading {
    margin-bottom: 6rem;
    font-size: 3.75rem;
  }

  .dealmed-home-serve__heading {
    margin-bottom: 6rem;
    font-size: 3.75rem;
  }

  .dealmed-home-account {
    .dealmed-container {
      padding: 100px 20px;
    }
  }

  .dealmed-home-account__content {
    .dealmed-container {
      padding: 0;
    }
  }

  .dealmed-home-account__image {
    width: 760px;
  }

  .dealmed-home-about {
    .dealmed-container {
      padding-top: 120px;
    }
  }

  .dealmed-home-about__text {
    margin-bottom: 195px;
  }

  .dealmed-home-about__item-icon {
    width: 1.75rem;
    height: 1.75rem;

    &.dealmed-icon {
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  .dealmed-home-about__item-title,
  .dealmed-home-about__item-text {
    font-size: 1.25rem;
  }

  .dealmed-home-business {
    .dealmed-container {
      padding: 325px 20px 175px;
    }
  }

  .dealmed-home-business__image {
    width: 495px;
  }
}

@media only screen and (min-width: 80em) {
  .dealmed-home-categories__heading {
    font-size: 4rem;
  }

  .dealmed-home-categories__banner {
    padding: 65px 0;
  }

  .dealmed-home-categories__text {
    font-size: 1.375rem;
  }

  .dealmed-home-run__heading {
    font-size: 4rem;
  }

  .dealmed-home-solutions__heading {
    font-size: 4rem;
  }

  .dealmed-home-serve__heading {
    font-size: 4rem;
  }

  .dealmed-home-account__heading {
    font-size: 4rem;
  }

  .dealmed-home-about__item {
    padding: 2rem 1.5rem;
  }

  .dealmed-home-about__item-icon {
    width: 2rem;
    height: 2rem;

    &.dealmed-icon {
      width: 2rem;
      height: 2rem;
    }
  }
}

@media only screen and (min-width: 85em) {
  .dealmed-home-hero {
    .dealmed-container {
      padding: 110px 0;
    }
  }

  .dealmed-home-hero__image {
    width: 670px;
  }

  .dealmed-home-hero__content {
    .dealmed-container {
      padding: 0 16px;
    }
  }

  .dealmed-home-hero__heading {
    font-size: 3.5rem;
  }

  .dealmed-home-categories {
    margin-bottom: 6rem;
  }

  .dealmed-home-categories__banner {
    padding: 95px 0;
  }

  .dealmed-home-categories__category {
    font-size: 3.5rem;
  }

  .dealmed-home-about__item {
    padding: 2rem;
  }
}

@media only screen and (min-width: 90em) {
  .dealmed-home-hero__image {
    width: 680px;
  }

  .dealmed-home-hero__heading {
    font-size: 3.75rem;
  }

  .dealmed-home-categories__banner {
    padding: 125px 0;
  }

  .dealmed-home-categories__category {
    font-size: 3.75rem;
  }

  .dealmed-home-account {
    .dealmed-container {
      padding: 130px 20px;
    }
  }

  .dealmed-home-account__content {
    .dealmed-container {
      padding: 0;
    }
  }

  .dealmed-home-account__image {
    width: 870px;
  }
}

@media only screen and (min-width: 95em) {
  .dealmed-home-hero {
    margin-bottom: 8rem;

    .dealmed-container {
      padding: 115px 0;
    }
  }

  .dealmed-home-hero__image {
    width: 705px;
  }

  .dealmed-home-hero__content {
    .dealmed-container {
      padding: 0 16px;
    }
  }

  .dealmed-home-hero__heading {
    font-size: 3.875rem;
  }

  .dealmed-home-categories__banner {
    padding: 135px 0;
  }

  .dealmed-home-categories__category {
    font-size: 3.875rem;
  }

  .dealmed-home-run__image {
    max-height: 500px;
  }
}

@media only screen and (min-width: 100em) {
  .dealmed-home-hero__image {
    width: 720px;
  }

  .dealmed-home-hero__heading {
    font-size: 4rem;
  }

  .dealmed-home-categories__banner {
    padding: 145px 0;
  }

  .dealmed-home-categories__category {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 105em) {
  .dealmed-home-hero {
    margin-bottom: 10rem;

    .dealmed-container {
      padding: 135px 0;
    }
  }

  .dealmed-home-hero__image {
    width: 770px;
  }

  .dealmed-home-hero__content {
    .dealmed-container {
      padding: 0 16px;
    }
  }

  .dealmed-home-run__image {
    top: 0;
    max-height: 600px;
  }
}

.alert-banner-wrapper {
  background-color: #f4f3f1;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  a {
    color: #7e7c78;
    background-color: white;
    padding: 6px 12px;
    border-radius: 6px;
    margin-left: 16px;
    font-size: 13px;

    &:hover {
      color: #b92a47;
      background-color: #ffe3e3;
    }
  }

  .alert-banner-text {
    margin: 0px;
    text-align: center;
    & > p {
      font-family: 'IBM Plex Sans';
    }
    // @media (max-width: $screen-sm-min) {
    //   line-height: 175%;
    //   padding:0 20px;
    // }
  }

  @media (max-width: $screen-sm-min) {
    font-size: 14.5px;
    line-height: 16px;
    font-weight: 600;
  }
}

#homepage-hero-text-container {
  @media (min-device-width: 768px) {
    width: 66%;
  }
}
