.dealmed-dialog {
  &[data-reach-dialog-overlay] {
    z-index: 6000;
  }

  &__content {
    width: fit-content;
    min-width: 300px !important;
    border-radius: 5px;
  }
}
