.dealmed-product {
  padding: 1.25rem;
  border: 1px solid $grey-light;
  border-radius: 5px;
}

.dealmed-product__image-wrapper {
  display: flex;
  margin-bottom: 0.625rem;
  height: 315px;
}

.dealmed-product__image-link {
  width: 100%;
}

.dealmed-product__image {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.dealmed-product__content {
  display: flex;
  flex-direction: column;
}

.dealmed-product__name-link {
  display: flex;
  margin-bottom: 0.625rem;
  color: $grey-dark-2;

  &:hover,
  &:focus {
    color: $red-dark;
  }
}

.dealmed-product__name {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 600;
}

.dealmed-product__info {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 0.625rem;
}

.dealmed-product__manufacturer {
  margin: 0;
  color: $red-dark;
}

.dealmed-product__id,
.dealmed-product__mpn {
  margin: 0;
  color: $grey-mid-2;
}

.dealmed-product__selections {
  display: flex;
  margin-bottom: 0.625rem;
  gap: 0.75rem;
  flex-wrap: wrap; //<--for mobile

  .dealmed-number-input {
    // margin-right: 1.25rem;
    min-width: 100px;
    flex: 0.5;
  }
}

.dealmed-product__purchase {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.625rem;
}

.dealmed-product__state {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.dealmed-product__license {
  margin-left: 1rem;
}

.dealmed-product__license-icon {
  width: 1.2rem;
  height: 1.2rem;
}

.dealmed-product__login {
  color: $red-dark;
  font-weight: 700;

  &:hover,
  &:focus {
    color: $grey-dark;
  }
}

.dealmed-product__login-text {
  margin: 0;
}

.dealmed-product__price {
  margin: 0;
  font-size: 1.75rem;
}

.dealmed-product__availability {
  display: flex;
  align-items: center;

  &--available {
    color: $green-light;
  }

  &--unavailable {
    color: $red-dark;
  }
}

.dealmed-product__availability-icon-wrapper {
  display: flex;
  margin-right: 0.5rem;
}

.dealmed-product__availability-icon {
  width: 20px;
  height: 20px;
}

.dealmed-product__availability-text {
  margin: 0;
  font-size: 18px;
  margin-top: 3px;
}

.dealmed-product__add-to-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 8px;
  width: 100%;
  height: 48px;
  // min-height: 60px;
  color: $white;
  background-color: #b92a47;
  border: none;
  text-transform: uppercase;
  border-radius: 4px;
  @media screen and (max-width: 767px) {
    height: 32px;
  }

  .dealmed-spinner {
    &::before {
      border-color: hsla(100, 100%, 100%, 0.5) $white hsla(100, 100%, 100%, 0.5)
        hsla(100, 100%, 100%, 0.5);
    }
  }

  &:hover,
  &:focus {
    background-color: #990000;
  }
}

.listing__products-box-buttons-addtocart-title {
  margin-right: 0.5rem;
}

.dealmed-product__add-to-cart-icon-wrapper {
  // margin-left: 0.5rem;
}

.dealmed-product__add-to-cart-icon {
  width: 2rem;
  height: 2rem;
  @media screen and (max-width: 767px) {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.dealmed-product__unable {
  margin: 0;
  color: #ed2525;
  font-weight: 700;
  font-size: 12px;
  line-height: 125%;
}

@media only screen and (min-width: 48em) {
  .dealmed-product {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .dealmed-product__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .dealmed-product__details {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex: 1;
  }
}

@media only screen and (min-width: 75em) {
  .dealmed-product__name {
    font-size: 2rem;
  }

  .dealmed-product__manufacturer,
  .dealmed-product__id,
  .dealmed-product__mpn {
    font-size: 1.125rem;
  }

  .dealmed-product__price {
    font-size: 2rem;
  }
}
