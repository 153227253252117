.dealmed-content-section {
  padding: 2.5rem 0;

  .dealmed-container {
    max-width: 90rem;
  }
}

@media only screen and (min-width: 48em) {
  .dealmed-content-section {
    padding: 4rem 0;

    .dealmed-container {
      display: flex;
      gap: 30px;
    }

    .dealmed-content-heading {
      flex: 1;
    }

    .dealmed-content-list {
      flex: 1;
    }
  }
}

@media only screen and (min-width: 62em) {
  .dealmed-content-section {
    padding: 6rem 0;

    .dealmed-container {
      gap: 90px;
    }
  }
}

@media only screen and (min-width: 75em) {
  .dealmed-content-section {
    padding: 7rem 0;

    .dealmed-container {
      gap: 120px;
    }
  }
}

@media only screen and (min-width: 90em) {
  .dealmed-content-section {
    .dealmed-container {
      gap: 170px;
    }
  }
}
