@import '../../assets/CSS/globals';

.searchbar-wrapper {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 14px;
    right: 14px;
    border: 1px solid #b92a47;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 300ms;
  }

  &:hover,
  &:focus-within {
    &::before {
      opacity: 1;
    }
  }
}

.search-icon {
  background-color: transparent;
  color: black;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  width: 47px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: color 300ms, background-color 300ms;
  z-index: 0;

  &:hover,
  &:focus {
    color: #ffffff !important;
    background-color: #b92a47;
  }
}

.searchbar-input {
  position: relative;
  outline-style: none;
  box-shadow: none;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 20px;
  padding: 7px 12px 3px 12px;
  border-right: none;
  background-color: #f1f3f7;
  border-bottom: none;
  border-radius: 5px;

  &:hover,
  &:focus {
    & + .search-icon {
      color: #b92a47;
    }
  }
}

.text-image-row {
  padding: 4px 2px;
}

.searchbar-result-image-col {
  padding: 0 10px 0 10px !important;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.rbt-menu {
  position: absolute;
  border: 1px solid rgb(255, 255, 255) !important;
  background-color: rgba(255, 255, 255, 0.97) !important;
  overflow: hidden !important;
  padding: 0 0 0 0 !important;
  margin-top: 0 !important;
  max-height: none !important;
  width: calc(100% - 22px) !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  z-index: 1;
}

.suggestion-text {
  display: flex;
  align-items: center;
}

.searchbar-all-results {
  color: $color-primary;
  font-size: 1.25em;
  text-align: center;
  cursor: pointer;
  padding: 8px 0;

  &:hover {
    color: $color-primary-hover-bright;
  }
}
