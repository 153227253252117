@import '../../assets/CSS/globals';

.bb-container {
  background-color: $color-secondary;
  padding: 5px 0 5px;
  color: white;
}

.bb-page {
  font-family: $font-archivo;
  padding-top: 75px;
  .contact-page-header {
    .mdi:before {
      font-size: 50px;
      color: $color-primary;
    }
    margin-bottom: 20px;
  }
  @media (max-width: $screen-xs-max) {
    padding-top: 35px;
    .contact-page-header-text {
      font-size: 1.2em;
      margin-bottom: 10px;
      color: gray;
      text-align: center;
    }
    .contact-page-headline {
      font-size: 1.2em;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 15px;
    }
    .contact-page-secondary {
      width: 79%;
      margin: 0 auto;
    }
    li.contact-page-secondary {
      margin-bottom: 10px;
    }
    .contact-page-secondary.contact-page_hours {
      width: 98%;
      font-size: 1.1em;
      text-align: center;
    }
  }
}

.bb-page-header-text {
  color: $color-primary;
  font-weight: bold;
  text-align: left;
  margin: 0 auto;
  font-size: 1.5em;
  @media (max-width: $screen-xl-min) {
    font-size: 1.3em;
    text-align: center;
  }
}

.bb-page-headline {
  font-size: 1.3em;
  color: $color-primary;
}

.bb-page-tel {
  color: black;
}

.bb-textarea {
  border: none;
  width: 100%;
  margin-left: 4px;
  background-color: #e7e7e7;
  border-bottom: $color-primary 2px solid;
}
