p {
  margin: 0;
  font-family: 'Campton Book', sans-serif;
}

h1 {
  font-family: 'Campton', sans-serif;
  font-size: 50px;
  letter-spacing: -1.8px;
  font-weight: 500;
  margin: 0;

  &.black {
    color: $clr-black;
  }

  &.white {
    color: $clr-white;
  }

  @include media('>=tablet') {
    font-size: 55px;
  }
}

h2 {
  color: $clr-black;
  font-family: 'Campton', sans-serif;
  font-size: 45px;
  letter-spacing: -1.63px;
  font-weight: 500;
  margin: 0;

  @include media('>=tablet') {
    font-size: 50px;
  }
}

h3 {
  color: $clr-black;
  font-family: 'Campton', sans-serif;
  font-size: 42px;
  letter-spacing: -1.25px;
  font-weight: 500;
  margin: 0;
}

h4 {
  color: $clr-black;
  font-family: 'Campton', sans-serif;
  margin: 0;
}
