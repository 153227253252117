.dealmed-popover {
  z-index: 9999;
}

.dealmed-popover__body {
  font-family: 'IBM Plex Sans';
  position: absolute;
  top: 8px;
  right: 0;
  padding: 0.75rem;
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 0 8px 2px hsla(0, 0%, 0%, 0.25);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    left: 50%;
    border-width: 0 13px 15px 13px;
    border-style: solid;
    border-color: transparent transparent hsla(0, 0%, 0%, 0.2) transparent;
    transform: translateX(-50%);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -14px;
    left: 50%;
    border-width: 0 12px 14px 12px;
    border-style: solid;
    border-color: transparent transparent $white transparent;
    transform: translateX(-50%);
  }

  &--override-position {
    right: auto;
    left: auto;
  }

  &--shopping-cart {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 1050;
    border: 1px solid #f4f3f1;
    border-radius: 12px; // Usar un valor único aquí
    background-color: $white;

    &--empty {
      height: null;
    }
  }
  &--notifications {
    width: 360px;
    padding: 0;
  }
}

.dealmed-popover-upsell-modal {
  display: block;
  position: fixed;
  width: fit-content;
  top: 10px;
  left: 0px;
  z-index: 1049;

  @media (max-width: 700px) {
    top: 100px;
    left: 0px;
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    left: 150px;
  }

  @media (min-width: 1201px) and (max-width: 1440px) {
    left: 250px;
    max-width: 800px;
  }

  @media (min-width: 1441px) and (max-width: 1920px) {
    left: 210px;
  }

  @media (min-width: 1921px) {
    left: 400px;
  }

  @media (min-width: 2100px) {
    left: 500px;
  }
}

.dealmed-header__cart-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  transition: opacity 0.3s ease;
  z-index: 1049;
  display: block;
}

.dealmed-popover-upsell-modal-header {
  text-align: center;
  font-size: 40px;

  color: #fff;
  text-shadow: 0px 2px 9.4px rgba(0, 0, 0, 0.71);
  margin-bottom: 24px;
  @media (max-width: 1439px) {
    margin-left: 150px;
    text-align: left;
  }
}
.dealmed-popover-upsell-modal-wrapper {
  display: flex;
  justify-content: center;
  max-width: 1200px;
  @media (max-width: 1439px) {
    max-width: 600px;
  }
  overflow: hidden;
}
.dealmed-popover-upsell-modal-container {
  z-index: 1049;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: calc(100% - 290px);
  height: 100%;
}
