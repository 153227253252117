.dealmed-checkbox {
  display: flex;
  align-items: center;
  margin: 0;
  transition: color 150ms ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $red-dark;

    .dealmed-checkbox__checkbox {
      border-color: $red-dark;
    }
  }
}

.dealmed-checkbox__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
  color: $white;
  border: 1px solid $grey-dark;

  .dealmed-checkbox--checked & {
    background-color: $red-dark;
    border-color: $red-dark;
  }
}
